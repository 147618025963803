import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import data from "../../../data/data";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectWrap2() {
  const projectSlider = {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next-c",
      prevEl: ".swiper-button-prev-c",
    },
  };

  return (
    <>
      <section className="history-area" style={{ marginTop: "150px" }}>
        <div className="container">
          <div
            className="title-wrap"
            style={{
              maxWidth: "100%",
            }}
          >
            <div className="sec-title">
              <span>Portfolio</span>
              <h2>
                Discover highlights from our portfolio of client projects and
                flagship solutions.
              </h2>
            </div>
          </div>
          {data.map((project) => (
            <div
              className="single-history"
              id={project.title}
              style={{ paddingTop: "100px", paddingBottom: "50px" }}
              key={project.id}
            >
              <div
                className="history wow animate fadeInLeft"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="circle">
                  <div className="inner" />
                </div>
                <div className="history-cnt">
                  <div
                    className="history-cnt-inner"
                    style={{ marginRight: "auto" }}
                  >
                    <h4 className="text-start">{project.longTitle}</h4>
                    <p className="fs-5 text-start">{project.description}</p>

                    <div className="mt-4">
                      <h5
                        className="mb-2 fs-4"
                        style={{
                          color: "#75dab4",
                        }}
                      >
                        Features:
                      </h5>
                      <ul className="mt-2">
                        {project.features.map((feature) => (
                          <li key={feature.id} className="mb-1">
                            <h6 className="fw-semibold me-2 d-inline ">
                              {feature.featureName}:
                            </h6>
                            {feature.featureDescription}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <button
                      style={{
                        borderRadius: "10px",
                        fontWeight: "600",
                        transition: "all 0.5s ease-out 0s",
                        margin: " 15px auto",
                        padding: "3px 7px",
                      }}
                      onClick={() => window.open(project.link, "_blank")}
                    >
                      Live Preview
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="history wow animate fadeInRight"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="circle">
                  <div className="inner" />
                </div>
                <div className="history-thumb history-thumb-project-img">
                  <Swiper
                    {...projectSlider}
                    className="swiper work-process-slide"
                  >
                    <div className="swiper-wrapper">
                      {project.media.map((image, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                          <div className="work-thumb">
                            <img
                              src={process.env.PUBLIC_URL + image}
                              alt="project image"
                              className="mx-auto w-100 portfolio-project-img"
                              onClick={() =>
                                window.open(project.link, "_blank")
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </div>
                    <div className="project-nav-arrows">
                      <div className="swiper-button-prev-c me-2">
                        <i className="bi bi-arrow-left" />
                      </div>
                      <div className="swiper-button-next-c">
                        <i className="bi bi-arrow-right" />
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default ProjectWrap2;
