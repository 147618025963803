import React from 'react';
import { useState, useEffect } from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import HomePageOne from '../page/home/HomePageOne';
import Preloader from './Preloader';
import { ReactComponent as CompanyIcon } from '../../assets/img/icons/logo.svg';
import { WhatsAppWidget } from '../whatsapp widget/index';

function MainLayout() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div style={{ zIndex: 2, position: 'relative' }}>
            {' '}
            <WhatsAppWidget
              CompanyIcon={CompanyIcon}
              companyName={'Digital Shifts Team'}
              phoneNumber='212610475046'
              message={"Hello! Let's talk about your needs or questions."}
              replyTimeText={"We'll answer you ASAP"}
            />
          </div>
          <Header />
          <HomePageOne />
          <Footer />
        </>
      )}
    </>
  );
}

export default MainLayout;
