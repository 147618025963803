import React, { useEffect, useState } from "react";
import { Table, Spin, Input, Button, Space, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ContactService } from "../../../../services/services";

const ContactsSection = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [expandedRowKey, setExpandedRowKey] = useState(null); // Track expanded row key
  const generateColor = (length) => {
    const hue = (350 * length) / 20; // Vary hue based on the length (adjust 20 as needed)
    return `hsl(${hue}, 50%, 60%)`; // Generate an HSL color
  };
  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const response = await ContactService.getAllContacts({
          page: currentPage,
          limit: 10000, // Assuming you want to fetch all contacts
        });

        if (isMounted) {
          setData(response.data.results);
          setTotalData(response.data.totalResults);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => <a href={`contacts/${record.id}`}>{text}</a>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Subject",
      width: 400,
      dataIndex: "subject",
      key: "subject",
      ...getColumnSearchProps("subject"),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      render: (text, record) => {
        const color = generateColor(text.length);
        return (
          <Tag color={color} key={text}>
            <span style={{ color: "black" }}>{text.toUpperCase()}</span>
          </Tag>
        );
      },
    },
  ];

  const isLoading = !data.length;

  // Function to handle row expansion
  const handleExpand = (record) => {
    if (expandedRowKey === record.key) {
      setExpandedRowKey(""); // Collapse the row if it's already expanded
    } else {
      setExpandedRowKey(record.key); // Expand the clicked row
    }
  };

  const expandedRowRender = (record) => (
    <div>
      {record.message && (
        <p>
          <strong>Message:</strong> {record.message}
        </p>
      )}
    </div>
  );

  return (
    <Spin spinning={isLoading} size="large">
      <Table
        columns={columns}
        dataSource={data.map((record) => ({
          ...record,
          key: record.id,
        }))}
        pagination={{
          current: currentPage,
          pageSize: 6,
          total: totalData,
          position: ["bottomCenter"],
          onChange: (page) => setCurrentPage(page),
        }}
        expandable={{
          expandedRowRender: expandedRowRender,
          expandedRowKeys: [expandedRowKey],
          onExpand: (expanded, record) => handleExpand(record),
        }}
      />
    </Spin>
  );
};

// Function to add custom search functionality for each column
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() =>
        document.querySelector(`[placeholder*="${dataIndex}"]`).select()
      );
    }
  },
});

export default ContactsSection;
