import Typography from "antd/es/typography/Typography";
import React from "react";
import ReactApexChart from "react-apexcharts";
const { Title } = Typography;

const SubscribersChart = ({ subscribersTimeline }) => {
  // Get today's date
  const today = new Date().toISOString().split("T")[0];

  // Get all unique dates from the data
  let allDates = [];
  for (let type in subscribersTimeline) {
    allDates = [...allDates, ...Object.keys(subscribersTimeline[type])];
  }

  // Add today's date if it doesn't exist
  if (!allDates.includes(today)) {
    allDates.push(today);
  }
  allDates = [...new Set(allDates)].sort();

  const fillMissingDates = (typeData) => {
    let filledData = {};
    let lastValue = 0;

    allDates.forEach((date) => {
      // If the date exists in the typeData, update the last value.
      if (typeData[date]) {
        lastValue = typeData[date];
      }

      // Assign the last known value to the date.
      filledData[date] = lastValue;
    });

    return filledData;
  };

  const filledGlobal = fillMissingDates(subscribersTimeline.global);
  const filledExpert = fillMissingDates(subscribersTimeline.expert);
  const filledCustomer = fillMissingDates(subscribersTimeline.customer);
  const filledGuest = fillMissingDates(subscribersTimeline.guest);

  const series = [
    {
      name: "Global",
      data: Object.values(filledGlobal),
    },
    {
      name: "Expert",
      data: Object.values(filledExpert),
    },
    {
      name: "Customer",
      data: Object.values(filledCustomer),
    },
    {
      name: "Guest",
      data: Object.values(filledGuest),
    },
  ];

  const options = {
    chart: {
      type: "line",
    },
    xaxis: {
      categories: allDates,
    },
    colors: ["#000000", "#315645", "#008053", "#80CAAC"],
  };

  return (
    <div id="chart">
      <div>
        <Title level={5}>Top Scored Experts </Title>
      </div>
      <ReactApexChart
        height={350}
        options={options}
        series={series}
        type="line"
      />
    </div>
  );
};

export default SubscribersChart;
