import React from "react";
import { Route, Redirect } from "react-router-dom";
import { setAuthToken } from "../services/services";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  if (token) {
    setAuthToken(token);
  }
  const isAuthenticated = !!localStorage.getItem("token"); // Check if a valid token exists

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
