import React from "react";
import { Avatar, List } from "antd";
import Typography from "antd/es/typography/Typography";

const { Title } = Typography;

const TopExperts = ({ topExperts }) => (
  <div id="chart">
    <div>
      <Title level={5}>Top Scored Experts </Title>
    </div>
    <List
      itemLayout="horizontal"
      dataSource={topExperts}
      renderItem={(expert) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                src={
                  expert.image
                    ? expert.image
                    : `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${expert.id}`
                }
              />
            }
            title={
              <a href={`/admin/experts/${expert.id}`}>
                {expert.name.toUpperCase()}
              </a>
            }
            description={
              <div className="ant-list-item-meta-title">{expert.title}</div>
            }
            // Assuming there's a score property on each expert
          />
        </List.Item>
      )}
    />{" "}
  </div>
);

export default TopExperts;
