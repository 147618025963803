import React from "react";
import { Card, Row, Col, Typography, Spin } from "antd";
import {
  TeamOutlined,
  FunctionOutlined,
  MailOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import "./counts.css";

const Counts = ({ counts }) => {
  const { Title } = Typography;
  const count = [
    {
      today: "Total Experts",
      title: counts?.totalExperts,
      icon: <TeamOutlined />,
      bnb: "bnb2",
    },
    {
      today: "Tech & Tools Count",
      title: counts.uniqueTechAndToolsCount,
      icon: <FunctionOutlined />,
      bnb: "bnb2",
    },
    {
      today: "Inquiries Total",
      title: counts.inquiriesTotal,
      icon: <MailOutlined />,
      bnb: "redtext",
    },
    {
      today: "Newsletter Subscribers",
      title: counts.newsletterSubscribers,
      icon: <SnippetsOutlined />,
      bnb: "bnb2",
    },
  ];
  return (
    <>
      {" "}
      {count.map((c, index) => (
        <Col
          key={index}
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
          className="mb-24"
        >
          <Card bordered={false} className="criclebox ">
            <div className="number">
              <Row align="middle" gutter={[24, 0]}>
                <Col xs={18}>
                  <span>{c.today}</span>
                  <Title level={3}>
                    {c.title} <small className={c.bnb}>{c.persent}</small>
                  </Title>
                </Col>
                <Col xs={6}>
                  <div className="icon-box">{c.icon}</div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default Counts;
