import React, { useState } from "react";
import { NewsletterService } from "../../services/services"; // Update the path

function SubscribArea() {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        await NewsletterService.createNewsletter({ email });
        alert("Subscription successful!"); // Provide feedback to the user.
        setEmail(""); // Clear the email input.
      } catch (error) {
        error.response.data.message &&
          alert(`An error occurred : ${error.response.data.message} `); // Handle the error.
      }
    } else {
      alert("Please enter a valid email address.");
    }
  };

  return (
    <>
      <section className="subscribe-newsletter sec-mar-top">
        <div className="container">
          <div className="news-letter-content">
            <div className="row align-items-center">
              <div
                className="col-lg-6 wow animate fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="subscribe-cnt">
                  <span>Get In Touch</span>
                  <h3>Subscribe Our</h3>
                  <strong>Newsletter</strong>
                </div>
              </div>
              <div
                className="col-lg-6 wow animate fadeInRight"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="subscribe-form">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Type Your Email"
                    />
                    <input type="submit" defaultValue="connect" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SubscribArea;
