import React from "react";
import { Avatar, List, Icon } from "antd";
import Typography from "antd/es/typography/Typography";

const { Title } = Typography;

const RecentInquiries = ({ recentInquiries }) => {
  return (
    <div id="chart">
      <div>
        <Title level={5}>Recent Inquiries</Title>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={recentInquiries}
        renderItem={(inquiry) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  src={
                    inquiry.image
                      ? inquiry.image
                      : `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${inquiry.id}`
                  }
                />
              }
              title={
                <a href={`/admin/inquiries/${inquiry.id}`}>
                  {`${inquiry.name.toUpperCase()} - ${inquiry.subject} - ${
                    inquiry.comments || "No Comments"
                  }`}
                </a>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default RecentInquiries;
