import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./lineChart";

function LineChart({ creationTimeline }) {
  const { Title, Paragraph } = Typography;

  const timelineData = Object.entries(creationTimeline);

  const formatReadableDate = (isoDate) => {
    const date = new Date(isoDate);
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const dates = timelineData.map((entry) => formatReadableDate(entry[0]));
  const counts = timelineData.map((entry) => entry[1]);

  const updatedLineChartOptions = {
    ...lineChart.options,
    xaxis: {
      ...lineChart.options.xaxis,
      categories: dates,
    },
    colors: ["#5FCBA0"], // Adding the Digital Shifts color
  };

  const updatedLineChartSeries = [
    {
      name: "Number of Experts",
      data: counts,
    },
    // ... other series data if you have them
  ];

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Number of experts</Title>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={updatedLineChartOptions}
        series={updatedLineChartSeries}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
