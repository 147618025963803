import React, { useState, useEffect } from "react";
import { Input, Button, Spin, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { AuthService } from "../../../services/services";
import { useHistory } from "react-router-dom";

import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/admin/Dashboard");
    }
  }, [history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await AuthService.login({
        email,
        password,
      });
      const { token } = response.data.tokens.access;
      setLoading(false);
      localStorage.setItem("token", token);
      history.push("/admin/Dashboard");
    } catch (error) {
      setLoading(false);
      setError("Invalid email or password. Please try again.");
      console.error("Authentication Error:", error);
    }
  };

  return (
    <div className="login-container" style={{ backgroundColor: "#191A1C" }}>
      <div
        className="card"
        style={{ backgroundColor: "#E4E4E4", color: "#191A1C" }}
      >
        <h4 style={{ color: "#397d63" }}>Login</h4>
        <form onSubmit={handleSubmit}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="email"
            placeholder="Email"
            style={{ margin: "8px 0" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            style={{ margin: "8px 0" }}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Alert message={error} type="error" style={{ margin: "8px 0" }} />
          )}
          <div style={{ position: "relative" }}>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "-75px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Spin
                  size="large"
                  tip="Logging in..."
                  style={{ color: "#88C4AC" }}
                />
              </div>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                fullWidth
                style={{
                  backgroundColor: "#88C4AC",
                  borderColor: "#88C4AC",
                  color: "#191A1C",
                  margin: "16px 0",
                }}
              >
                Login
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
