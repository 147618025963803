import React from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const history = useHistory();
  localStorage.removeItem("token");
  history.push("/admin/login");
  return <div>Logout</div>;
};

export default Logout;
