import { Link } from 'react-router-dom';
import './project-styles.css';

function CallToActionSection() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <section className='cta-section text-white mt-5'>
      <div className='container'>
        <h2 className='cta-section__title text-center'>
          Prepared to turn your ideas into reality? We are here to help
        </h2>
        <div className='d-flex justify-content-center pt-4'>
          <Link
            to='/contact'
            onClick={scrollTop}
            className='call-to-action-btn'
          >
            Start a project with us
          </Link>
        </div>
      </div>
    </section>
  );
}

export default CallToActionSection;
