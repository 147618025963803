import React from 'react';
import { useLocation } from 'react-router-dom';
import SEO from '../../../utiles/seo/SEO';
import siteMetadata from '../../../utiles/seo/siteMetadata';
import AnimateLine from '../../common/AnimateLine';
import Breadcrumb from '../../common/Breadcrumb';
import SubscribArea from '../../common/SubscribArea';
import ProjectWrap2 from './ProjectWrap2';
import CallToActionSection from './CallToActionSection';

function Projects() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { title, description, keywords } =
    siteMetadata.paths[currentPath] || siteMetadata.common;

  return (
    <>
      <SEO
        path={currentPath}
        title={title}
        description={description}
        keywords={keywords}
      />
      <div className='digital-shifts-wrap'>
        <AnimateLine />
        <Breadcrumb pageName='Portfolio ' pageTitle='Portfolio' />
        <ProjectWrap2 />
        <CallToActionSection />
        <SubscribArea />
      </div>
    </>
  );
}

export default Projects;
