import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React from "react";
import Sidebar from "../Sidebar";
import ContactsSection from "./ContactsSection";

const Contacts = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={200}
        theme="dark"
        style={{
          backgroundColor: "#191a1c",
        }}
        className="sidebar-container"
      >
        <Sidebar selectedMenuItem={"Contacts"} />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "16px", overflow: "auto" }}>
          <ContactsSection />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Contacts;
