import React, { useEffect } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AnimateLine from "../../common/AnimateLine";

function Meeting() {
  useEffect(() => {
    // Create a script element for Calendly
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up to remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="digital-shifts-wrap">
        <AnimateLine />
        <Breadcrumb
          pageTitle="Schedule a Meeting"
          pageName="Schedule a Meeting"
        />
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/digitalshifts/30min?primary_color=5db593"
          style={{ minWidth: "320px", height: "700px", paddingTop: "90px" }}
        ></div>
        <SubscribArea />
      </div>
    </>
  );
}

export default Meeting;
