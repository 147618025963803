import React from 'react';
import Footer from './common/Footer';
import Header from './common/Header';
import { ReactComponent as CompanyIcon } from '../assets/img/icons/logo.svg';
import { WhatsAppWidget } from './whatsapp widget';

function Layout({ children }) {
  return (
    <>
      <Header />
      {children}

      {/* WhatsApp Widget to be displayed in all pages */}
      <div style={{ zIndex: 2, position: 'relative' }}>
        <WhatsAppWidget
          CompanyIcon={CompanyIcon}
          companyName={'Digital Shifts Team'}
          phoneNumber='212610475046'
          message={"Hello! Let's talk about your needs or questions."}
          replyTimeText={"We'll answer you ASAP"}
        />
      </div>
      <Footer />
    </>
  );
}

export default Layout;
