import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <footer className={props.footerAddclass}>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link onClick={scrollTop} to={"/"}>
                      <img
                        src={process.env.PUBLIC_URL + "/img/logodsf.png"}
                        alt="FooterImg"
                      />
                    </Link>
                  </div>
                  <p>We Boost Your Online Presence</p>
                  <br />
                  <ul className="social-follow">
                    <li>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/profile.php?id=100090865539745&_rdc=1&_rdr"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noopener noreferrer"
                        href="https://twitter.com/Digital_Shifts"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/digital-shifts"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/digital.shifts/"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Our Services</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/services/website-development`}
                        onClick={scrollTop}
                      >
                        Website Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/services/mobile-app-development`}
                        onClick={scrollTop}
                      >
                        Mobile App Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/services/branding-digital-marketing`}
                        onClick={scrollTop}
                      >
                        Branding and Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/services/ui-ux-design`}
                        onClick={scrollTop}
                      >
                        UI/UX Design
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/services/it-consulting`}
                        onClick={scrollTop}
                      >
                        IT Consulting
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/about`}
                        onClick={scrollTop}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/services`}
                        onClick={scrollTop}
                      >
                        Services
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/projects`}
                        onClick={scrollTop}
                      >
                        Projects
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/contact`}
                        onClick={scrollTop}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/terms`}
                        onClick={scrollTop}
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="tel:+212610475046">+212610475046</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="tell:Contact@digitalshifts.net">
                        Contact@digitalshifts.net
                      </a>{" "}
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>11 Rue Le Titien, Casablanca 20390</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div>
              All rights reserved. Copyright © <span>Digital Shifts</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
