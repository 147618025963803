import React from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../../utiles/seo/SEO";
import siteMetadata from "../../../utiles/seo/siteMetadata";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AboutWrap from "./AboutWrap";

function About() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { title, description, keywords } =
    siteMetadata.paths[currentPath] || siteMetadata.common;

  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        path={currentPath}
      />
      <Breadcrumb pageName="About Us" pageTitle="About Us" />
      <AboutWrap />
      <SubscribArea />
    </>
  );
}

export default About;
