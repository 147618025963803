import React from "react";
import { Menu, Button } from "antd";
import {
  DashboardOutlined,
  TeamOutlined,
  MailOutlined,
  FileTextOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./Sidebar.css";
const Sidebar = ({ selectedMenuItem }) => {
  const history = useHistory();

  const onMenuItemClick = (key) => {
    history.push(`/admin/${key.toLowerCase()}`);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    history.push("/admin/login");
  };
  const menuItems = [
    { key: "Dashboard", label: "Dashboard", icon: <DashboardOutlined /> },
    { key: "Experts", label: "Experts", icon: <TeamOutlined /> },
    { key: "Contacts", label: "Contacts", icon: <MailOutlined /> },
    { key: "Newsletter", label: "Newsletter", icon: <FileTextOutlined /> },
  ];
  return (
    <div>
      <div className="admin-logo">
        <img src="/img/logods.png" alt="Admin Logo" />
      </div>
      <Menu
        mode="vertical"
        selectedKeys={[selectedMenuItem]}
        onClick={({ key }) => onMenuItemClick(key)}
        style={{
          backgroundColor: "#191a1c",
        }}
        items={menuItems}
      ></Menu>
      <div className="admin-logout">
        <Button
          onClick={handleLogout}
          type="default"
          icon={<LogoutOutlined />}
          block
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
