import React from "react";
import { Link, useLocation } from "react-router-dom";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ServiceDetailsWebDev from "./ServiceDetailsWebDev";
import ServiceDetailsMobileAppDev from "./ServiceDetailsMobileAppDev";
import ServiceDetailsDigitalMarketing from "./ServiceDetailsDigitalMarketing";
import ServiceDetailsUIUX from "./ServiceDetailsUIUX";
import ServiceDetailsITConsulting from "./ServiceDetailsITConsulting";
import siteMetadata from "../../../utiles/seo/siteMetadata";
import SEO from "../../../utiles/seo/SEO";

function ServiceDetails({ service }) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  let serviceComponent;

  switch (service) {
    case "Website Development":
      serviceComponent = <ServiceDetailsWebDev />;
      break;
    case "Mobile App Development":
      serviceComponent = <ServiceDetailsMobileAppDev />;
      break;
    case "Branding and Digital Marketing":
      serviceComponent = <ServiceDetailsDigitalMarketing />;
      break;
    case "UI/UX Design":
      serviceComponent = <ServiceDetailsUIUX />;
      break;
    case "IT Consulting":
      serviceComponent = <ServiceDetailsITConsulting />;
      break;
    default:
      serviceComponent = <div>Service details not found.</div>;
  }

  const location = useLocation();
  const currentPath = location.pathname;
  const { title, description, keywords } =
    siteMetadata.paths[currentPath] || siteMetadata.common;

  return (
    <>
      <SEO
        path={currentPath}
        title={title}
        description={description}
        keywords={keywords}
      />
      <div className="digital-shifts-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Service Details" pageTitle={service} />
        {serviceComponent}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="buttons"
        >
          <div className="cmn-btn">
            <div className="line-1" />
            <div className="line-2" />
            <Link
              to={`${process.env.PUBLIC_URL}/contact`}
              onClick={scrollTop}
              style={{ width: "500px" }}
            >
              Start My Project
            </Link>
          </div>
        </div>
        <SubscribArea />
      </div>
    </>
  );
}

export default ServiceDetails;
