import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p>
                Unlock the full potential of your business with our
                comprehensive digital solutions
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            className="row g-4"
          >
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={1}
                image="/img/icons/service-icon-3.png"
                title="Website Development"
                details="Captivating designs and seamless functionality for an
                    impactful online presence."
                path="services/website-development"
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={2}
                image="/img/icons/service-icon-2.png"
                title="Mobile App Development"
                details="Deliver personalized mobile apps for captivating user
                    experiences on the move."
                path="services/mobile-app-development"
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={3}
                image="/img/icons/service-icon-1.png"
                title="Branding & Digital Marketing"
                details=" Amplify brand visibility, attract audiences, and drive
                    conversions."
                path="services/branding-digital-marketing"
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={4}
                image="/img/icons/service-icon-5.png"
                title="UI/UX Design"
                details="Stunning interfaces that delight users and enhance
                    engagement."
                path="services/ui-ux-design"
              />
            </div>
            <div
              class="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <ServiceCard
                serviceNumber={5}
                image="/img/icons/service-icon-5.png"
                title="IT Consulting"
                details=" Expert guidance for optimized IT strategies and
                    infrastructure."
                path="services/it-consulting"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
