/* global cloudinary */
import React, { useState } from "react";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import { UploadOutlined } from "@ant-design/icons";
import { Spin } from "antd";

// Styles
import "react-tagsinput/react-tagsinput.css";
import "./BeOurExpertWrapper.css";

// Service
import { ExpertService } from "../../../services/services";

function BeOurExpertWrapper() {
  // Options and States
  const options = [
    { value: "webDevelopment", label: "Web Development" },
    { value: "mobileDevelopment", label: "Mobile Development" },
    { value: "digitalMarketing", label: "Branding & Digital Marketing" },
    { value: "uiUxDesign", label: "UI & UX Design" },
    { value: "itConsulting", label: "IT Consulting" },
  ];

  const defaultFormData = {
    name: "",
    title: "",
    email: "",
    contactNumber: "",
    linkedin: "",
    yearsOfExperience: "1",
    portfolioUrl: "",
    briefStatement: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [techAndTools, setTechAndTools] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (selectedOptions) => {
    setExpertise(selectedOptions.map((option) => option.value));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    try {
      const submitData = {
        ...formData,
        image: imageURL,
        techAndTools,
        areaOfExpertise: expertise,
      };

      // Log the object
      await ExpertService.createExpert(submitData);
      setSuccessMessage("Successfully joined the Digital Shifts Network!");
      setErrorMessage(""); // Clear out any previous errors
      window.scrollTo(0, 0); // Scroll to top
      setFormData(defaultFormData);

      setTechAndTools([]);
      setExpertise([]);
      setImageURL("");
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Error:", error);
      setErrorMessage(
        `There was an issue submitting the form:  ${error.response.data.message}`
      );
      setSuccessMessage(""); // Clear out any previous success message
      window.scrollTo(0, 0); // Scroll to top
    }
  };
  const [inputValue, setInputValue] = useState(""); // <-- New state
  const handleInputTagChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputBlur = (e) => {
    const inputValue = e.target.value.trim();
    if (inputValue) {
      setTechAndTools((prevTags) => [...prevTags, inputValue]);
      setInputValue("");
    }
  };
  const openCloudinaryWidget = () => {
    cloudinary.openUploadWidget(
      {
        cloudName: "dbgva5f9l",
        uploadPreset: "ekpuybec",
        sources: ["local"],
        cropping: true,
        resourceType: "image",
        allowedFormats: ["jpg", "png", "jpge", "gif", "bmp"],
      },
      function (error, result) {
        if (!error && result && result.event === "success") {
          const uploadedFormat = result.info.format;
          const allowedFormats = ["jpg", "png", "jpeg", "gif", "bmp"];

          if (allowedFormats.includes(uploadedFormat)) {
            setImageURL(result.info.secure_url);
          } else {
            // Notify the user about the unsupported format
            alert("Unsupported file format. Please upload a valid image.");
          }
        }
      }
    );
  };

  return (
    <section className="be-our-expert-area">
      <div className="container">
        <div className="be-our-expert-content">
          <div className="be-our-expert-form">
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="be-our-expert-text">
              <label className="expert-title">Be Our Expert</label>
              <p className="expert-description">
                At Digital Shifts, we believe in fostering a collaborative and
                diverse community of experts who share our passion for
                innovation and excellence. If you possess exceptional skills and
                expertise in the digital realm, we invite you to join our team
                of dedicated professionals.
              </p>
              <label className="form-title">
                Why Become a Digital Shifts Expert?
              </label>
              <ul className="why-expert-list">
                <li>
                  <b>Exciting Projects:</b> As a Digital Shifts expert, you'll
                  have the opportunity to work on cutting-edge projects that
                  challenge your skills and expand your knowledge.
                </li>
                <li>
                  <b>Collaborative Environment:</b> Join a team of like-minded
                  professionals who value teamwork, knowledge-sharing, and
                  continuous learning.
                </li>
                <li>
                  <b> Career Growth:</b> We are committed to nurturing our
                  experts' professional growth, offering opportunities for
                  career advancement and skill development.
                </li>
              </ul>
            </div>
            <br />
            <label className="form-title">Fill out the form to apply:</label>
            <form onSubmit={handleSubmit} className="expertForm">
              <div className="form-group">
                <label htmlFor="name">
                  {" "}
                  Name: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  Email: <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="contactNumber">
                  Contact Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="tel"
                  id="contactNumber"
                  name="contactNumber"
                  placeholder="Contact Number"
                  value={formData.contactNumber}
                  onChange={handleInputChange}
                  required
                />
              </div>{" "}
              <div className="form-group">
                <label htmlFor="title">
                  Title (e.g. Front-end Developer, Designer, Media Buyer, etc.):{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter your title"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  Area of Expertise <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  required
                  isMulti
                  options={options}
                  onChange={(selectedOptions) => handleChange(selectedOptions)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="techAndTools">
                  Tech and Tools (e.g., Reactjs, Adobe Photoshop, Facebook Ads,
                  Flutter, etc.): <span style={{ color: "red" }}>*</span>
                </label>
                <TagsInput
                  value={techAndTools}
                  inputProps={{
                    className: "react-tagsinput-input",
                    placeholder: "Type and press Enter to add",
                    required: !techAndTools.length,
                    onBlur: handleInputBlur,
                    value: inputValue,
                    onChange: handleInputTagChange,
                  }}
                  onChange={(newTags) => {
                    setTechAndTools(newTags);
                    setInputValue("");
                  }}
                  className="selectclass"
                />
              </div>
              <div className="form-group">
                <label htmlFor="yearsOfExperience">Years of Experience:</label>
                <select
                  value={formData.yearsOfExperience}
                  onChange={handleInputChange}
                  id="yearsOfExperience"
                  name="yearsOfExperience"
                >
                  <option value="1">1 Year</option>
                  <option value="2">2 Years</option>
                  <option value="3">3 Years</option>
                  <option value="4">4 Years</option>
                  <option value="+5">+5 Years</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="linkedin">LinkedIn Profile:</label>
                <input
                  id="linkedin"
                  name="linkedin"
                  placeholder="LinkedIn Profile"
                  value={formData.titlinkedinle}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="portfolioUrl">
                  Portfolio URL or Main Projects
                </label>
                <input
                  value={formData.portfolioUrl}
                  onChange={handleInputChange}
                  type="text"
                  id="portfolioUrl"
                  name="portfolioUrl"
                  placeholder="Portfolio URL / Main Projects"
                />
              </div>
              <div className="form-group ds-form-group">
                <button
                  type="button"
                  className="ds-btn"
                  onClick={openCloudinaryWidget}
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <UploadOutlined />
                  Upload your Image
                </button>
                {imageURL && (
                  <div className="ds-image-preview">
                    <img
                      onClick={() => setImageURL("")}
                      src={imageURL}
                      alt="Uploaded preview"
                      title="Tap to delete"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="briefStatement">
                  Brief Statement (Max 200 words):
                </label>
                <textarea
                  value={formData.briefStatement}
                  onChange={handleInputChange}
                  id="briefStatement"
                  name="briefStatement"
                  cols="30"
                  rows="6"
                  placeholder="Your message"
                />
              </div>
              {loading ? (
                <div>
                  <Spin size="large" style={{ color: "#88C4AC" }} />
                </div>
              ) : (
                <input
                  type="submit"
                  value="Join Digital Shifts Network"
                  className="form-submit"
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BeOurExpertWrapper;
