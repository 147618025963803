import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React from "react";
import Sidebar from "../Sidebar";
import DashboardSection from "./dashboardSection";

const Dashboard = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={200}
        theme="dark"
        style={{
          backgroundColor: "#191a1c",
        }}
        className="sidebar-container"
      >
        <Sidebar selectedMenuItem={"Dashboard"} />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "16px", overflow: "auto" }}>
          <DashboardSection />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
