import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import BeOurExpertWrapper from "./BeOurExpertWrapper";
import AnimateLine from "../../common/AnimateLine";

function BeOurExpert() {
  return (
    <>
      <div className="digital-shifts-wrap">
        <AnimateLine />
        <Breadcrumb pageTitle="Be Our Expert" pageName="Be Our Expert" />
        <BeOurExpertWrapper />
        <SubscribArea />
      </div>
    </>
  );
}

export default BeOurExpert;
