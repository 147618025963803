const data = [
  {
    id: 1,
    title: "Eldoradrom",
    longTitle: "Eldoradrom: E-Commerce Flash Sale Store",
    description:
      "ldoradrom is an e-commerce website store built with Next.js and Tailwind CSS, designed specifically for flash sales. The website is fully responsive, working seamlessly on all devices, and is optimized for SEO and fast loading.",
    image: "img/project/digitalshifts_project_1.png",
    media: [
      "img/project/digitalshifts_project_1.png",
      "img/project/eldoradrom_media_2.png",
      "img/project/eldoradrom_media_3.png",
    ],
    features: [
      {
        id: 1,
        featureName: "Responsive Design",
        featureDescription:
          "The website is fully responsive and works on all devices.",
      },
      {
        id: 2,
        featureName: "SEO Friendly",
        featureDescription:
          "The website is SEO friendly and optimized for search engines.",
      },
      {
        id: 3,
        featureName: "Fast Loading",
        featureDescription:
          "The website is optimized for fast loading and performance.",
      },
      {
        id: 3,
        featureName: "Backoffice Integration",
        featureDescription:
          " Includes a backoffice built with NestJS for efficient management.",
      },
      {
        id: 3,
        featureName: "Hosted on Amazon",
        featureDescription: " Leveraging Amazon Web Services for hosting.",
      },
    ],
    category: "web",
    details: "Digifts Ecom",
    section: "digifts-ecom",
    link: "https://eldoradrom-store.vercel.app",
  },
  {
    id: 4,
    title: "Digifts Ecom",
    longTitle: "Digifts Ecom: React eCommerce Admin and Store",
    description:
      "Digifts Ecom is an advanced e-commerce platform featuring both admin and store interfaces. Built with React.js and Tailwind CSS, it integrates a complete REST API, supporting full-stack MERN (MongoDB, Express, React, Node) development.",
    image: "img/project/digitalshifts_project_4.png",
    media: [
      "img/project/digitalshifts_project_4.png",
      "img/project/digifts_ecom_media_2.png",
      "img/project/digifts_ecom_media_3.png",
    ],
    features: [
      {
        id: 1,
        featureName: "Tech Stack",
        featureDescription:
          "React.js, Tailwind CSS, Express.js, MongoDB with Mongoose validation.",
      },
      {
        id: 2,
        featureName: "Admin Capabilities",
        featureDescription:
          "Manage products, categories, attributes, coupons, and staff with CRUD operations.",
      },
      {
        id: 3,
        featureName: "Dynamic Translation",
        featureDescription: "Supports dynamic data translation.",
      },
      {
        id: 4,
        featureName: "PWA Support",
        featureDescription: "Includes Progressive Web App features.",
      },
      {
        id: 5,
        featureName: "Backend Integration",
        featureDescription: "Fully integrated with REST API.",
      },
    ],
    category: "web",
    details: "Digifts Ecom",
    section: "digifts-ecom",
    link: "https://digifts-ecom.vercel.app",
  },
  {
    id: 5,
    title: "E-Trip",
    longTitle: "E-Trip: E-commerce Website Store",
    description:
      "E-Trip is an e-commerce website store built with Next.js and Tailwind CSS. The website is fully responsive and works on all devices. The website is SEO friendly and optimized for search engines. The website is optimized for fast loading and performance.",
    image: "img/project/digitalshifts_project_7.png",
    media: [
      "img/project/digitalshifts_project_7.png",
      "img/project/e_trip_media_2.png",
      "img/project/e_trip_media_3.png",
    ],
    features: [
      {
        id: 1,
        featureName: "Responsive Design",
        featureDescription:
          "The website is fully responsive and works on all devices.",
      },
      {
        id: 2,
        featureName: "SEO Friendly",
        featureDescription:
          "The website is SEO friendly and optimized for search engines.",
      },
      {
        id: 3,
        featureName: "Fast Loading",
        featureDescription:
          "The website is optimized for fast loading and performance.",
      },
    ],
    category: "web",
    details: "Digifts Ecom",
    section: "digifts-ecom",
    link: "https://store-tripshop.netlify.app",
  },
  {
    id: 2,
    title: "IM Creation",
    longTitle: "IM Creation: Communication Agency Website",
    description:
      "IM Creation is a communication agency website built to support the development of businesses through digital solutions. The website is fully responsive, SEO-friendly, and optimized for fast performance.",
    image: "img/project/digitalshifts_project_2.png",
    media: [
      "img/project/digitalshifts_project_2.png",
      "img/project/im_creation_media_2.png",
      "img/project/im_creation_media_3.png",
    ],
    features: [
      {
        id: 1,
        featureName: "Responsive Design",
        featureDescription:
          "The website is fully responsive and works on all devices.",
      },
      {
        id: 2,
        featureName: "SEO Friendly",
        featureDescription:
          "The website is SEO friendly and optimized for search engines.",
      },
      {
        id: 3,
        featureName: "Fast Loading",
        featureDescription:
          "The website is optimized for fast loading and performance.",
      },
    ],
    category: "web",
    details: "IM Creation",
    section: "im-creation",
    link: "https://im-creation.com",
  },
  {
    id: 3,
    title: "IA Vox",
    longTitle: "IA Vox: E-Learning Platform",
    description:
      "IA Vox is an innovative e-learning platform built to provide top-tier education led by seasoned educators from prestigious engineering schools. The platform is fully responsive, SEO-friendly, and optimized for fast performance.",
    image: "img/project/digitalshifts_project_3.png",
    media: [
      "img/project/digitalshifts_project_3.png",
      "img/project/ia_vox_media_2.png",
      "img/project/ia_vox_media_3.png",
    ],
    features: [
      {
        id: 1,
        featureName: "Responsive Design",
        featureDescription:
          "The platform is fully responsive and works on all devices.",
      },
      {
        id: 2,
        featureName: "SEO Friendly",
        featureDescription:
          "The platform is SEO friendly and optimized for search engines.",
      },
      {
        id: 3,
        featureName: "Fast Loading",
        featureDescription:
          "The platform is optimized for fast loading and performance.",
      },
    ],
    category: "web",
    details: "IA Vox",
    section: "ia-vox",
    link: "https://iavox.net",
  },
  {
    id: 6,
    title: "Solid",
    longTitle:
      "Solid: Site Vitrine pour Société Les Infrastructures du Détroit",
    description:
      "Solid est un site vitrine pour la société Les Infrastructures du Détroit. Fondée en 2003, SOLID a commencé son activité dans le domaine de génie civil et ouvrages d’art avant de l’élargir aux aménagements hydrauliques en 2012 et dans la construction des réservoirs de stockage en 2018. Le site est conçu pour être entièrement responsive, SEO-friendly et optimisé pour une performance rapide.",
    image: "img/project/digitalshifts_project_6.png",
    media: [
      "img/project/digitalshifts_project_6.png",
      "img/project/solid_media_2.png",
      "img/project/solid_media_3.png",
    ],
    features: [
      {
        id: 1,
        featureName: "Responsive Design",
        featureDescription:
          "Le site est entièrement responsive et fonctionne sur tous les appareils.",
      },
      {
        id: 2,
        featureName: "SEO Friendly",
        featureDescription:
          "Le site est optimisé pour les moteurs de recherche.",
      },
      {
        id: 3,
        featureName: "Fast Loading",
        featureDescription:
          "Le site est optimisé pour un chargement rapide et des performances élevées.",
      },
    ],
    category: "web",
    details: "Solid",
    section: "solid",
    link: "https://www.solid-maroc.com",
  },
  // {
  //   id: 5,
  //   title: 'Clinique Marrakech',
  //   longTitle: 'Clinique Marrakech: E-commerce Website Store',
  //   description:
  //     'Eldoradrom is an e-commerce website store built with Next.js and Tailwind CSS. The website is fully responsive and works on all devices. The website is SEO friendly and optimized for search engines. The website is optimized for fast loading and performance.',
  //   image: 'img/project/digitalshifts_project_5.png',
  //   media: [
  //     'img/project/digitalshifts_project_5.png',
  //     'img/project/clinique_media_2.png',
  //     'img/project/clinique_media_3.png',
  //   ],
  //   features: [
  //     {
  //       id: 1,
  //       featureName: 'Responsive Design',
  //       featureDescription:
  //         'The website is fully responsive and works on all devices.',
  //     },
  //     {
  //       id: 2,
  //       featureName: 'SEO Friendly',
  //       featureDescription:
  //         'The website is SEO friendly and optimized for search engines.',
  //     },
  //     {
  //       id: 3,
  //       featureName: 'Fast Loading',
  //       featureDescription:
  //         'The website is optimized for fast loading and performance.',
  //     },
  //   ],
  //   category: 'web',
  //   details: 'Digifts Ecom',
  //   section: 'digifts-ecom',
  //   link: 'https://cliniquelemarrakech.ma',
  // },
  // {
  //   id: 1,
  //   title: 'Website Developmentre',
  //   image: 'img/project/project-1n.jpg',
  //   category: 'web',
  //   details: 'Digifts Ecom',
  //   section: 'digifts-ecom',
  // },
  // {
  //   id: 2,
  //   title: 'Website Development',
  //   image: 'img/project/project-2n.jpg',
  //   category: 'web',
  //   details: 'Digifts ERP/CRM',
  //   section: 'digifts-erp-crm',
  // },
  // {
  //   id: 3,
  //   title: 'Website Development',
  //   image: 'img/project/project-3n.jpg',
  //   category: 'web',
  //   details: 'Digifts ELearn',
  //   section: 'digifts-elearn',
  // },
  // {
  //   id: 6,
  //   title: 'Mobile App Development',
  //   image: 'img/project/project-6n.jpg',
  //   category: 'Mobile App Development',
  //   details: 'Fitness Center App',
  //   section: 'fitness-center-app',
  // },
  // {
  //   id: 4,
  //   title: 'UI/UX Design',
  //   image: 'img/project/project-4n.jpg',
  //   category: 'UI',
  //   details: 'Tabibcom Mockup',
  //   section: 'tabibcom-mockup',
  // },
  // {
  //   id: 5,
  //   title: 'Branding and Digital Marketing',
  //   image: 'img/project/project-5n.jpg',
  //   category: 'Marketing',
  //   details: 'Maximize Engagement & Sales',
  //   section: 'engagement-sales',
  // },

  // {
  //   id: 7,
  //   title: '3D Design',
  //   image: 'img/project/project-5.jpg',
  //   category: 'Graphic',
  //   details: 'Low Poly Base Mesh',
  // },
  // {
  //     id:8,
  //     title:"3D Design",
  //     image:"img/project/project-1.jpg",
  //     category: "Graphic",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:9,
  //     title:"UI Kit",
  //     image:"img/project/project-2.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:10,
  //     title:"UI Kit",
  //     image:"img/project/project-4.jpg",
  //     category: "UI",
  //     details:"Low Poly Base Mesh",
  // },
  // {
  //     id:11,
  //     title:"App",
  //     image:"img/project/project-3.jpg",
  //     category: "App",
  //     details:"Mobile Crypto Wallet",
  // },
  // {
  //     id:12,
  //     title:"App",
  //     image:"img/project/project-1.jpg",
  //     category: "App",
  //     details:"Mobile Crypto Wallet",
  // },
];

export default data;
