import React from "react";

function HistoryArea() {
  return (
    <>
      <section className="history-area " style={{ marginTop: "120px" }}>
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Values</span>
              <h2>Our Guiding Principles</h2>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-11.png"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <h4>Customer-Centric Approach</h4>
                  <p>
                    At Digital Shifts, our compass always points to the
                    customer. We take pride in our unwavering commitment to
                    putting your needs first. From project inception to
                    completion, we listen, adapt, and deliver solutions that
                    align with your vision. Your satisfaction is the driving
                    force behind our every decision and action.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <h4>Passion for Innovation</h4>
                  <p>
                    Innovation is the heartbeat of Digital Shifts. We don't just
                    embrace the latest trends; we create them. Our passionate
                    team of visionaries and technologists is dedicated to
                    pushing the boundaries of what's possible. We believe in
                    harnessing the power of cutting-edge technology to drive
                    transformation and deliver exceptional results.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="300ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-12.png"}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-13.png"}
                  alt="history-img"
                />
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <h4>Global Mindset, Local Touch</h4>
                  <p>
                    Digital Shifts combines a global mindset with a local touch.
                    We draw from the vast pool of international expertise while
                    providing you with personalized, local support. Our approach
                    ensures that you receive world-class solutions tailored to
                    your specific needs. No matter where you are, we speak your
                    language and understand your unique challenges.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div
              className="history wow animate fadeInLeft"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <h4>Adaptable and Agile</h4>
                  <p>
                    Agility is in our DNA. In an ever-evolving digital
                    landscape, we thrive on adaptability and agility. We are
                    quick to pivot, seize opportunities, and navigate
                    challenges. Our nimble approach ensures that your projects
                    remain on track, responsive to changing market dynamics, and
                    poised for success.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="history wow animate fadeInRight"
              data-wow-delay="500ms"
              data-wow-duration="1500ms"
            >
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/img/timeline-14.png"}
                  alt="history-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HistoryArea;
