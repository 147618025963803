import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Our Portfolio</span>
              <h2>Projects</h2>
              <p>
                Explore a glimpse of our achievements through a curated
                selection of client projects and standout products.
              </p>
            </div>
          </div>
          <ProjectCardFilter />
        </div>
      </div>
    </>
  );
}

export default ProjectWrap;
