import { Spin } from "antd";
import React, { useState } from "react";
import { ContactService } from "../../../services/services";

function ContactWrapper() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); // Track loading state
  const [successMessage, setSuccessMessage] = useState(""); // Success message
  const [errorMessage, setErrorMessage] = useState(""); // Error message

  const { name, email, phone, subject, message } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Disable the submit button while sending the API request
    setLoading(true);

    // Prepare the contact data object
    const contactData = {
      name,
      email,
      phone,
      subject,
      message,
    };

    try {
      // Send data to createContact API
      await ContactService.createContact(contactData);

      // Handle the response, e.g., show a success message
      setSuccessMessage("Request created successfully!");
      setErrorMessage(""); // Clear any previous error message

      // Clear the form fields after submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      // Handle errors, e.g., show an error message
      setErrorMessage("Error creating your request: " + error.message);
      setSuccessMessage(""); // Clear any previous success message
    } finally {
      // Re-enable the submit button
      setLoading(false);

      // Scroll to the top of the page
    }
  };

  return (
    <>
      <section className="contact-area sec-mar">
        <div className="container">
          <div className="sec-title layout2">
            <span>Hello there! Ready to Discuss Your Project?</span>
            <h2>
              We're excited to collaborate with you. Reach out to us today
            </h2>
          </div>

          <div className="col-md-4">
            <a
              href="https://api.whatsapp.com/send?phone=212610475046"
              className="whatsapp-button inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text-sm text-white transition-colors duration-300 bg-blue-600 rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="25"
                height="25"
                viewBox="0 0 48 48"
              >
                {/* WhatsApp icon SVG */}
              </svg>
              <span className="mx-2">Message on WhatsApp</span>
            </a>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="contact-left">
                <div className="informations">
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="info">
                      <h3>Phone</h3>
                      <a href="tel:+212610475046">+212610475046</a>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="info">
                      <h3>Email</h3>
                      <a href="tell:Contact@digitalshifts.net">
                        Contact@digitalshifts.net
                      </a>
                    </div>
                  </div>
                  <div className="single-info">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h3>Location</h3>
                      <p>11 Rue Le Titien, Casablanca 20390</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="informations">
                {" "}
                <ul className="social-follow">
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/profile.php?id=100090865539745&_rdc=1&_rdr"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://twitter.com/Digital_Shifts"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/digital-shifts"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/digital.shifts/"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="getin-touch">
                <div className="row">
                  <div
                    className="contact-form wow animate fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <form action="#" method="post" onSubmit={onSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            value={name}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="tel"
                            name="phone"
                            placeholder="Enter your phone number"
                            value={phone}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={subject}
                            onChange={onChange}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <textarea
                            name="message"
                            cols={30}
                            rows={10}
                            placeholder="How can we help you?"
                            value={message}
                            onChange={onChange}
                            required
                          />
                          {successMessage && (
                            <div className="success-message">
                              {successMessage}
                            </div>
                          )}
                          {errorMessage && (
                            <div className="error-message">{errorMessage}</div>
                          )}
                          {loading ? (
                            <div>
                              <Spin size="large" style={{ color: "#88C4AC" }} />
                            </div>
                          ) : (
                            <input type="submit" value="Send Message" />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactWrapper;
