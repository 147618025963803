import { Spin } from "antd";
import React, { useState } from "react";
import { ContactService } from "../../../services/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ReservationArea() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    websiteType: "",
    budget: "",
  });

  const [loading, setLoading] = useState(false); // Track loading state
  const [successMessage, setSuccessMessage] = useState(""); // Success message
  const [errorMessage, setErrorMessage] = useState(""); // Error message

  const { name, email, phone, companyName, websiteType, budget } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Disable the submit button while sending the API request
    setLoading(true);

    // Prepare the contact data object
    const contactData = {
      name,
      email,
      phone,
      subject: websiteType,
      message: `Company Name: ${companyName}, Budget: ${budget}`,
    };

    try {
      // Send data to createContact API
      await ContactService.createContact(contactData);

      // Handle the response, e.g., show a success message
      // setSuccessMessage('Our team will contact you soon!');

      toast.success("Our team will contact you soon!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // setErrorMessage(''); // Clear any previous error message

      // Clear the form fields after submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        companyName: "",
        websiteType: "",
        budget: "",
      });
    } catch (error) {
      // Handle errors, e.g., show an error message
      // setErrorMessage('Error creating your request: ' + error.message);
      toast.error("Error creating your request: " + error.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      // Re-enable the submit button
      setLoading(false);

      // Scroll to the top of the page
    }
  };

  return (
    <section className="reservation-area sec-mar">
      <div className="container">
        <div
          className="title-wrap wow animate fadeInUp"
          data-wow-delay="200ms"
          data-wow-duration="1500ms"
        >
          <div className="sec-title">
            <span>Start Your Project Today</span>
            <h2>Free Project Quote</h2>
            <p>
              Kickstart your digital journey with us! Request your free project
              quote now.
            </p>
          </div>
        </div>

        <div className="getin-touch">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div
                className="contact-form wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <form action="#" method="post" onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter your full name"
                        value={name}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="companyName"
                        placeholder="Enter your company name"
                        value={companyName}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Enter your phone number"
                        value={phone}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="form-group w-100">
                        <label htmlFor="websiteType">Website Type:</label>
                        <select
                          value={websiteType}
                          onChange={onChange}
                          id="websiteType"
                          name="websiteType"
                          style={{
                            marginBottom: "25px",
                          }}
                          required
                        >
                          <option value="">Select Type</option>
                          <option value="E-commerce platform">
                            E-commerce platform
                          </option>
                          <option value="Showcase site">Showcase site</option>
                          <option value="Reservation site or appointment making">
                            Reservation site or appointment making
                          </option>
                          <option value="Online portfolio">
                            Online portfolio
                          </option>
                          <option value="Professional blog">
                            Professional blog
                          </option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className="form-group w-100"
                        style={{ marginBottom: "30px" }}
                      >
                        <label>Budget Range:</label>
                        <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row align-items-md-center w-100">
                          <div className="form-check d-flex align-items-end">
                            <input
                              type="radio"
                              id="budget0"
                              name="budget"
                              value="-1000"
                              onChange={onChange}
                              className="form-check-input me-2 w-auto"
                              required
                            />
                            <label
                              htmlFor="budget1"
                              className="form-check-label text-nowrap m-0"
                            >
                              -1000$
                            </label>
                          </div>
                          <div className="form-check d-flex align-items-end">
                            <input
                              type="radio"
                              id="budget1"
                              name="budget"
                              value="1000 - 2000$"
                              onChange={onChange}
                              className="form-check-input me-2 w-auto"
                              required
                            />
                            <label
                              htmlFor="budget1"
                              className="form-check-label text-nowrap m-0"
                            >
                              1000 - 2000$
                            </label>
                          </div>
                          <div className="form-check d-flex align-items-end">
                            <input
                              type="radio"
                              id="budget2"
                              name="budget"
                              value="2000 - 4000$"
                              onChange={onChange}
                              className="form-check-input me-2 w-auto"
                            />
                            <label
                              htmlFor="budget2"
                              className="form-check-label text-nowrap m-0"
                            >
                              2000 - 4000$
                            </label>
                          </div>
                          <div className="form-check d-flex align-items-end">
                            <input
                              type="radio"
                              id="budget3"
                              name="budget"
                              value="4000 - 10000$"
                              onChange={onChange}
                              className="form-check-input me-2 w-auto"
                            />
                            <label
                              htmlFor="budget3"
                              className="form-check-label text-nowrap m-0"
                            >
                              4000 - 10000$
                            </label>
                          </div>
                          <div className="form-check d-flex align-items-end">
                            <input
                              type="radio"
                              id="budget3"
                              name="budget"
                              value="+10000$"
                              onChange={onChange}
                              className="form-check-input me-2 w-auto"
                            />
                            <label
                              htmlFor="budget4"
                              className="form-check-label text-nowrap m-0"
                            >
                              +10000$
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {loading ? (
                        <div className="mx-auto d-flex justify-content-center">
                          <Spin size="large" style={{ color: "#88C4AC" }} />
                        </div>
                      ) : (
                        <input
                          className="w-full mw-100 rounded-1"
                          type="submit"
                          value="Send Your Request"
                        />
                      )}
                    </div>
                  </div>
                  <ToastContainer />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReservationArea;
