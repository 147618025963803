import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
function Breadcrumb(props) {
  const [open, setOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <section className="breadcrumbs" style={{ height: "400px"}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>{props.pageTitle}</h1>
                  <span>
                    <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                    <i className="bi bi-arrow-right" />
                    {props.pageName}
                  </span>
                  <div className={props.pageName === "Service Details"  ? "bread": "breadcrumb-video"}>
                  {props.pageName === "Service Details" ? (
                      
                      <div className="sidebar-widget" style={{}}>
                        <h4>Services</h4>
                        <ul className="category">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/services/website-development`}
                              onClick={scrollTop}
                            >
                              Website Development
                              <i className="bi bi-arrow-right" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/services/mobile-app-development`}
                              onClick={scrollTop}
                            >
                              Mobile App Development
                              <i className="bi bi-arrow-right" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/services/branding-digital-marketing`}
                              onClick={scrollTop}
                            >
                              Branding and Digital Marketing
                              <i className="bi bi-arrow-right" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/services/ui-ux-design`}
                              onClick={scrollTop}
                            >
                              UI/UX Design
                              <i className="bi bi-arrow-right" />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/services/it-consulting`}
                              onClick={scrollTop}
                            >
                              IT Consulting
                              <i className="bi bi-arrow-right" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    
                  ) : (
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/breadcrumb-video.jpg"
                      }
                      alt="breadcrumbImg"
                    />
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <React.Fragment>
        <ModalVideo
          channel={"youtube"}
          videoId="T7SIxJOoQd8"
          isOpen={open}
          onClose={() => setOpen(false)}
        ></ModalVideo>
      </React.Fragment>
    </>
  );
}

export default Breadcrumb;
