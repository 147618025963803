import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Button,
  Space,
  Typography,
  Modal,
  Tag,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { NewsletterService } from "../../../../services/services";

const NewsletterSection: React.FC = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };
  const generateColor = (length) => {
    const hue = (250 * length) % 360; // Vary hue based on the length and wrap around at 360
    return `hsl(${hue}, 50%, 70%)`; // Generate an HSL color
  };

  const handleOk = async () => {
    try {
      await NewsletterService.createNewsletter({ email, type });
      setEmail("");
      setType("");
      setIsModalVisible(false);
      fetchNewsletterData();
    } catch (error) {
      console.error("Error adding email:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    fetchNewsletterData();
  }, []);

  const fetchNewsletterData = async () => {
    setLoading(true);
    try {
      const response = await NewsletterService.getAllNewsletters({
        page: 1,
        limit: 10000, // assuming 6 is your desired page size
      });
      setData(response.data.results);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      email: "",
      type: "",
      createdAt: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      editable: true,
      ...getColumnSearchProps("email"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      editable: true,
      ...getColumnSearchProps("type"),
      render: (text, record) => {
        const color = generateColor(text.length);
        return (
          <Tag color={color} key={text}>
            <span style={{ color: "black" }}>{text.toUpperCase()}</span>
          </Tag>
        );
      },
    },
    {
      title: "Date Joined",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) =>
        isEditing(record) ? undefined : new Date(text).toLocaleDateString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <EditOutlined
                style={{
                  color: "#4b715b",
                }}
              />
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.email)}
            >
              <Typography.Link>
                <DeleteOutlined
                  style={{
                    color: "#C0090B",
                  }}
                />
              </Typography.Link>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const addNewsletter = async (newsletterData) => {
    try {
      await NewsletterService.createNewsletter(newsletterData);
      fetchNewsletterData(); // Refresh the data after adding
    } catch (error) {
      console.log("Error adding newsletter:", error);
    }
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newsletterToUpdate = data.find((item) => item.key === key);

      if (row) {
        await NewsletterService.updateNewsletter(key, row);
      }
      fetchNewsletterData(); // Refresh the data after updating
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = async (email) => {
    await NewsletterService.deleteNewsletter(email);
    fetchNewsletterData(); // Refresh the data after deleting
  };

  return (
    <Form form={form} component={false}>
      <Spin spinning={loading} size="large">
        <Modal
          title="Add Email"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            style: { backgroundColor: "rgb(136, 197, 173)" },
          }}
        >
          <Form>
            <Form.Item
              label="Email"
              rules={[{ required: true, message: "Please input an email!" }]}
            >
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Form.Item
              label="Type"
              rules={[{ required: true, message: "Please select a type!" }]}
            >
              <Input value={type} onChange={(e) => setType(e.target.value)} />
            </Form.Item>
          </Form>
        </Modal>

        <Button
          type="primary"
          style={{
            margin: "12px",
            backgroundColor: "rgb(136 197 173)",
            position: "relative",
            left: "90%",
          }}
          onClick={showModal}
        >
          Add Email
        </Button>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={data.map((record) => ({
            ...record,
            key: record.id,
          }))}
          pagination={true}
        />
      </Spin>
    </Form>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

// Function to add custom search functionality for each column
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() =>
        document.querySelector(`[placeholder*="${dataIndex}"]`).select()
      );
    }
  },
});

export default NewsletterSection;
