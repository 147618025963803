import React from "react";
import { Link } from "react-router-dom";

function HeroArea() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img
            src={process.env.PUBLIC_URL + "/img/hero-banner.jpg"}
            alt="HeroBanner"
          />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li>
              <a href="https://web.facebook.com/profile.php?id=100090865539745">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/digital.shifts/">Instagram</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/digital-shifts">
                Linkedin
              </a>
            </li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>
                    Empowering Modern Businesses
                    <span> with Digital Solutions.</span>
                  </h1>
                  <p>
                    We offer innovative solutions for websites, mobile apps,
                    digital Marketing, and consulting. Transform your business
                    and achieve success in the digital landscape with our
                    expertise and forward-thinking approach. Let's navigate the
                    digital world together.
                  </p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link
                        to={`${process.env.PUBLIC_URL}/contact`}
                        onClick={scrollTop}
                      >
                        Start My Project
                      </Link>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link
                        to={`${process.env.PUBLIC_URL}/about`}
                        onClick={scrollTop}
                      >
                        About Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroArea;
