import Typography from "antd/es/typography/Typography";
import React from "react";
import ReactApexChart from "react-apexcharts";

const TechChart = ({ technologyFrequency }) => {
  const { Title } = Typography;
  // Sort technologyFrequency based on the frequency
  const sortedTechData = Object.entries(technologyFrequency)
    .sort((a, b) => b[1] - a[1])
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

  const techNames = Object.keys(sortedTechData);
  const techCounts = Object.values(sortedTechData);

  // DigitalShifts colors
  const colors = ["#5FCBA0", "#88C4AC"];

  const options = {
    series: [
      {
        data: techCounts,
      },
    ],
    chart: {
      height: 500,
      type: "bar",
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: techNames,
      labels: {
        style: {
          colors: colors,
          fontSize: "12px",
        },
      },
    },
  };

  return (
    <div id="chart">
      <div>
        <Title level={5}>Top 20 Technologies & Tools</Title>
      </div>
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default TechChart;
