import React from "react";
import { Link } from "react-router-dom";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-8">
              <div className="service-details-content">
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  Mobile App Development Services
                </h3>
                <p>
                  In our Mobile App Development services, we embark on an
                  innovative journey to turn your app ideas into reality. Our
                  process spans from conceptualization to deployment, utilizing
                  state-of-the-art technologies and methodologies to craft
                  custom mobile solutions that perfectly align with your
                  business objectives. Leveraging programming languages such as
                  Swift, Kotlin, and JavaScript, in combination with modern
                  frameworks and libraries, we engineer robust and scalable
                  mobile applications. Our proficient team ensures seamless
                  integration of databases, APIs, and user interfaces,
                  guaranteeing a seamless and engaging user experience. We are
                  dedicated to delivering mobile apps that not only meet
                  functional requirements but also adhere to industry best
                  practices, keeping your business at the forefront of the
                  digital landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-banner" style={{ marginTop: "80px" }}>
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:+212610475046">+212610475046</a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>01</span>Planning and Design
                    </h4>
                    <p>
                      <b>Idea Exploration:</b> Collaborate closely with the
                      customer to explore their app idea, understand their
                      specific requirements, and outline project constraints.
                      <br />
                      <b>Concept Design:</b> Create initial concept designs for
                      the mobile app's user interface (UI) and functionality,
                      focusing on user experience and functionality.
                      <br />
                      <b>Technology Stack:</b> Choose the right technology
                      stack, including platforms (iOS, Android), programming
                      languages, and frameworks.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/blog/app-mobile-design.jpg"
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/img/blog/app-dev.jpg"}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>02</span>App Development and Testing
                    </h4>
                    <p>
                      <b>Development Phase:</b> Our skilled developers bring the
                      app to life, writing efficient and clean code while
                      following industry best practices.
                      <br />
                      <b>Thorough Testing:</b> Rigorously test the app,
                      including unit testing, integration testing, and user
                      acceptance testing, to ensure it functions seamlessly.
                      <br />
                      <b>Deployment Support:</b> After the app passes all tests,
                      we assist in deploying it to the desired platforms,
                      providing support during the transition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>03</span>Maintenance and Growth
                    </h4>
                    <p>
                      <b>Continuous Support:</b> Our commitment extends beyond
                      deployment. We offer ongoing maintenance and support to
                      address issues, release updates, and enhance app
                      functionality.
                      <br />
                      <b>Performance Optimization:</b> Continuously monitor the
                      app's performance, making optimizations for improved
                      efficiency and a better user experience.
                      <br />
                      <b>Scalability:</b> As your business expands, we help you
                      scale the app to meet increased demands, ensuring it
                      remains a valuable asset.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/img/blog/dev-lunch.jpg"}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
