import React, { useState, useEffect } from "react";
import { Card, Row, Col, Typography, Spin } from "antd";

import {
  ExpertService,
  ContactService,
  NewsletterService,
  ReportingService,
} from "../../../../services/services";
import LineChartExpert from "./KPI/LineChartExpert";
import PieExpert from "./KPI/PieExpert";
import Counts from "./KPI/Counts";
import TechChart from "./KPI/TechChart";
import TopExperts from "./KPI/TopExperts";
import RecentInquiries from "./KPI/RecentInquiries";
import InquiriesChart from "./KPI/InquiriesChart";
import RecentSubscribers from "./KPI/RecentSubscribers";
import SubscribersChart from "./KPI/SubscribersChart";
const { Title } = Typography;

const DashboardSection = () => {
  const [loading, setLoading] = useState(true);
  const [counts, setCounts] = useState();
  const [expertiseDist, setExpertiseDist] = useState({});
  const [creationTimeline, setCreationTimeline] = useState({});
  const [technologyFrequency, setTechnologyFrequency] = useState({});
  const [topExperts, setTopExperts] = useState([]);
  const [recentInquiries, setRecentInquiries] = useState([]);
  const [recentSubscribers, setRecentSubscribers] = useState([]);
  const [inquiriesTimeline, setInquiriesTimeline] = useState([]);
  const [subscribersTimeline, setSubscribersTimeline] = useState([]);

  useEffect(() => {
    // Fetch all data here
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await ReportingService.getCounts();
        setCounts(res.data.counts);
        setExpertiseDist(res.data.expertiseDist);
        setCreationTimeline(res.data.creationTimeline);
        setTechnologyFrequency(res.data.techAndToolsFrequency);
        setTopExperts(res.data.topExperts);
        setRecentInquiries(res.data.recentInquiries);
        setRecentSubscribers(res.data.recentSubscribers);
        setInquiriesTimeline(res.data.inquiriesTimeline);
        setSubscribersTimeline(res.data.subscribersTimeline);
      } catch (error) {
        console.error("Error fetching the data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getLabelFromValue = (value) => {
    const option = options.find((opt) => opt.value === value);
    return option ? option.label : value; // If not found, return the value itself
  };
  const options = [
    { value: "webDevelopment", label: "Web Development" },
    { value: "mobileDevelopment", label: "Mobile Development" },
    { value: "digitalMarketing", label: "Branding & Digital Marketing" },
    { value: "uiUxDesign", label: "UI & UX Design" },
    { value: "itConsulting", label: "IT Consulting" },
  ];

  if (loading) {
    return <Spin tip="Loading..." />;
  }
  let experts;
  return (
    <div className="layout-content">
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <Counts counts={counts} />
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <PieExpert expertiseDist={expertiseDist} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <TechChart technologyFrequency={technologyFrequency} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <TopExperts topExperts={topExperts} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <LineChartExpert creationTimeline={creationTimeline} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <RecentInquiries recentInquiries={recentInquiries} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <InquiriesChart inquiriesTimeline={inquiriesTimeline} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <RecentSubscribers recentSubscribers={recentSubscribers} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <SubscribersChart subscribersTimeline={subscribersTimeline} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardSection;
