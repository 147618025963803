import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React from "react";
import Sidebar from "../Sidebar";
import ExpertsSection from "./ExpertsSection";

const Experts = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className="sidebar-container"
        width={200}
        theme="dark"
        style={{
          backgroundColor: "#191a1c",
        }}
      >
        <Sidebar selectedMenuItem={"Experts"} />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "16px", overflow: "auto" }}>
          <ExpertsSection />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Experts;
