import React, { useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import data from '../../../data/data';
import { HashLink } from 'react-router-hash-link';
import SingleTeamCard from '../team/SingleTeamCard';

function ProjectCardFilter() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const [items, setItem] = useState(data);

  const filterItem = (catagoryitem) => {
    const updateItem = data.filter((curentelement) => {
      return curentelement.category === catagoryitem;
    });

    setItem(updateItem);
  };

  const initialState = { statementActive: '' };
  function reducer(state, action) {
    switch (action.type) {
      case 'dataAll':
        setItem(data);

        return { statementActive: 'dataAll' };
      case 'UI':
        filterItem('UI');
        return { statementActive: 'UI' };
      case 'web':
        filterItem('web');
        return { statementActive: 'web' };
      case 'developing':
        filterItem('developing');
        return { statementActive: 'developing' };
      case 'Graphic':
        filterItem('Graphic');
        return { statementActive: 'Graphic' };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      {/* <div className="row">
        <div className="col-12">
          <ul className="isotope-menu">
            <li
              onClick={() => dispatch({ type: "dataAll" })}
              className={state.statementActive === "dataAll" ? "active" : ""}
            >
              All
            </li>
            <li
              onClick={() => dispatch({ type: "UI" })}
              data-filter=".ui"
              className={state.statementActive === "UI" ? "active" : ""}
            >
              UI/UX
            </li>
            <li
              onClick={() => dispatch({ type: "web" })}
              data-filter=".web"
              className={state.statementActive === "web" ? "active" : ""}
            >
              Web Design
            </li>
            <li
              onClick={() => dispatch({ type: "developing" })}
              data-filter=".developing"
              className={state.statementActive === "developing" ? "active" : ""}
            >
              Developing
            </li>
            <li
              onClick={() => dispatch({ type: "Graphic" })}
              data-filter=".graphic"
              className={state.statementActive === "Graphic" ? "active" : ""}
            >
              Graphic Design
            </li>
          </ul>
        </div>
      </div> */}
      <div className='row g-4 project-items d-flex'>
        {items.slice(0, 6).map((element) => {
          const { title, link, image, id, details, section } = element;
          return (
            <div key={id} className='col-lg-4 col-md-6'>
              <div className='single-team single-team-border single-item-hover position-relative'>
                <div className='member-img'>
                  <img
                    src={`${process.env.PUBLIC_URL} ${image}`}
                    alt='Images'
                  />
                </div>
                <div className='team-inner'>
                  <h4>{title}</h4>

                  <div className='single-item-view-btn d-flex justify-content-center'>
                    <a href={link} target='_blank'>
                      Visit website
                    </a>
                    <HashLink
                      style={{
                        marginLeft: '30px',
                      }}
                      to={`${process.env.PUBLIC_URL}/projects#${title}`}
                    >
                      view details
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ProjectCardFilter;
