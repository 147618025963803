import axios from "axios";

// Set the base URL for your backend API : https://digitalshifts-server.onrender.com/v1/ or http://localhost:3000/v1/
axios.defaults.baseURL = "https://digitalshifts-server.onrender.com/v1/";

// Function to set the authentication token
export const setAuthToken = (token) => {
  if (token) {
    // Set the token as a default header for all requests
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // If there's no token, remove the Authorization header
    delete axios.defaults.headers.common["Authorization"];
  }
};

// Authentication API
export const AuthService = {
  // Login
  login: (credentials) => {
    return axios.post("/auth/login", credentials);
  },
  // Logout
  logout: () => {
    return axios.post("/auth/logout");
  },
};

// Expert API
export const ExpertService = {
  // Get all experts
  getAllExperts: (params) => {
    return axios.get("/experts", { params });
  },
  // Get a specific expert
  getExpert: (expertId) => {
    return axios.get(`/experts/${expertId}`);
  },
  // Create an expert
  createExpert: (expertData) => {
    return axios.post("/experts", expertData);
  },
  // Update an expert
  updateExpert: (expertId, expertData) => {
    return axios.put(`/experts/${expertId}`, expertData);
  },
  // Delete an expert
  deleteExpert: (expertId) => {
    return axios.delete(`/experts/${expertId}`);
  },
};

// reporting API
export const ReportingService = {
  getCounts: () => {
    return axios.get("/reporting");
  },
};

// Contact API
export const ContactService = {
  // Get all contacts
  getAllContacts: (params) => {
    return axios.get("/contacts", { params });
  },
  // Get a specific contact
  getContact: (contactId) => {
    return axios.get(`/contacts/${contactId}`);
  },
  // Create a contact
  createContact: (contactData) => {
    return axios.post("/contacts", contactData);
  },
  // Update a contact
  updateContact: (contactId, contactData) => {
    return axios.patch(`/contacts/${contactId}`, contactData);
  },
  // Delete a contact
  deleteContact: (contactId) => {
    return axios.delete(`/contacts/${contactId}`);
  },
};

// Newsletter API
export const NewsletterService = {
  // Get all newsletters
  getAllNewsletters: (params) => {
    return axios.get("/newsletter/subscribers", { params });
  },
  // Get a specific newsletter
  getNewsletter: (newsletterId) => {
    return axios.get(`/newsletter/${newsletterId}`);
  },
  // Create a newsletter
  createNewsletter: (newsletterData) => {
    return axios.post("/newsletter/subscribe", newsletterData);
  },
  // Update a newsletter
  updateNewsletter: (newsletterId, newsletterData) => {
    return axios.put(`/newsletter/${newsletterId}`, newsletterData);
  },
  // Delete a newsletter
  deleteNewsletter: (email) => {
    return axios.post("/newsletter/unsubscribe", { email });
  },
};
