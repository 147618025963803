import React from "react";
import { Link } from "react-router-dom";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-8">
              <div className="service-details-content">
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  UI/UX Design
                </h3>
                <p>
                  Our UI/UX Design services are dedicated to creating intuitive
                  and user-friendly digital experiences. We specialize in
                  crafting seamless user interfaces for websites and mobile apps
                  that enhance user engagement and satisfaction. Whether you're
                  starting a new project or improving an existing one, our UI/UX
                  design experts are here to ensure your digital product aligns
                  perfectly with your user's needs and preferences.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-banner" style={{ marginTop: "80px" }}>
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:+212610475046">+212610475046</a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>01</span>Understanding User Needs
                    </h4>
                    <p>
                      At DigitalShifts, our UI/UX design process begins with a
                      deep understanding of your user's needs. We take the time
                      to listen to your target audience, conduct user research,
                      and identify key user personas. This research forms the
                      foundation of our design approach, ensuring that the final
                      product caters to the preferences and expectations of your
                      users.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/blog/uiux-meeting.jpg"
                      }
                      alt="Understanding User Needs"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/img/blog/uiux-prtp.jpg"}
                      alt="Concept and Prototyping"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>02</span>Concept and Prototyping
                    </h4>
                    <p>
                      Once we have a clear understanding of user needs, we move
                      on to the concept and prototyping phase. Here, our
                      talented designers work on creating initial design
                      concepts and interactive prototypes. These prototypes
                      allow us to visualize and test different design ideas,
                      ensuring that the final user interface is both functional
                      and user-friendly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>03</span>User-Centered Design
                    </h4>
                    <p>
                      User experience is at the core of our design philosophy.
                      We prioritize user-centered design principles to create
                      interfaces that are intuitive, easy to navigate, and
                      visually appealing. Our team conducts usability testing
                      and iterative design to refine the user interface and
                      ensure it meets the needs of your target audience.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/img/blog/uiux-cmer.jpg"}
                      alt="User-Centered Design"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
