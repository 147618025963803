import React from 'react';
import AnimateLine from '../../common/AnimateLine';
import SubscribArea from '../../common/SubscribArea';
import ProjectWrap from '../project/ProjectWrap';
import PriceCardWrap from '../service/PriceCardWrap';
import TeamWrap from '../team/TeamWrap';
import AboutArea from './AboutArea';
import FeaturesArea from './FeaturesArea';
import HeroArea from './HeroArea';
import LatesPost from './LatesPost';
import PartnarArea from './PartnarArea';
import ServiceArea from './ServiceArea';
import Testimonial from './Testimonial';
import SEO from '../../../utiles/seo/SEO.js';
import siteMetadata from '../../../utiles/seo/siteMetadata';
import { useLocation } from 'react-router-dom';
import ReservationArea from './ReservationArea';
function HomePageOne() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { title, description, keywords } =
    siteMetadata.paths[currentPath] || siteMetadata.common;

  return (
    <>
      <SEO
        path={currentPath}
        title={title}
        description={description}
        keywords={keywords}
      />

      <div className='digital-shifts-wrap'>
        <AnimateLine />
        <HeroArea />
        <ServiceArea />
        <AboutArea image='/img/logods.png' />
        <FeaturesArea />

        <ProjectWrap />
        <PartnarArea />
        <ReservationArea />
        {/* <PriceCardWrap/> */}
        {/* <Testimonial/> */}
        {/* <TeamWrap/> */}
        {/* <LatesPost/> */}
        <SubscribArea />
      </div>
    </>
  );
}

export default HomePageOne;
