const siteMetadata = {
  common: {
    title: "Digital Shifts",
    description:
      "Empowering modern businesses with innovative digital solutions.",
    keywords:
      "web development, digital marketing, UI/UX design, IT consulting, Digital Shifts",
  },
  paths: {
    "/": {
      title: "Home - Digital Shifts",
      description:
        "Empowering modern businesses with innovative digital solutions.",
      keywords:
        "digital agency, technology, innovation, business solutions, Digital Shifts",
    },
    "/about": {
      title: "About Us - Digital Shifts",
      description:
        "Discover more about Digital Shifts, our values, mission, and expertise.",
      keywords: "about us, Digital Shifts, company mission, team, expertise",
    },
    "/contact": {
      title: "Contact Us - Digital Shifts",
      description:
        "Reach out to Digital Shifts for queries, support, or to start a project.",
      keywords:
        "contact, support, Digital Shifts, project inquiry, customer service",
    },
    "/services": {
      title: "Our Services - Digital Shifts",
      description:
        "Explore our diverse range of digital services tailored for your success.",
      keywords:
        "services, digital solutions, web development, mobile app development, Digital Shifts",
    },
    "/services/website-development": {
      title: "Website Development - Digital Shifts",
      description:
        "Custom website development services to boost your online presence.",
      keywords:
        "website development, web design, custom websites, Digital Shifts",
    },
    "/services/mobile-app-development": {
      title: "Mobile App Development - Digital Shifts",
      description: "Innovative mobile app solutions to engage users on the go.",
      keywords:
        "mobile app development, iOS, Android, app design, Digital Shifts",
    },
    "/services/branding-digital-marketing": {
      title: "Branding & Digital Marketing - Digital Shifts",
      description:
        "Elevate your brand with our strategic digital marketing services.",
      keywords:
        "branding, digital marketing, SEO, social media, Digital Shifts",
    },
    "/services/ui-ux-design": {
      title: "UI/UX Design - Digital Shifts",
      description:
        "Crafting stunning and user-friendly designs for your digital products.",
      keywords: "UI/UX design, user interface, user experience, Digital Shifts",
    },
    "/services/it-consulting": {
      title: "IT Consulting - Digital Shifts",
      description:
        "Expert IT consulting services to optimize and transform your business.",
      keywords:
        "IT consulting, technology advice, business solutions, Digital Shifts",
    },
    "/projects": {
      title: "Projects - Digital Shifts",
      description:
        "Discover more about Digital Shifts, our projects, products, and portfolio.",
      keywords:
        "projects, Digital Shifts, company psroject,digifts ecom, digifts elearn, digifts crm erp,digifts,ecommerce, team, expertise",
    },
  },
};

export default siteMetadata;
