import React from "react";
import { Link } from "react-router-dom";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-8">
              <div className="service-details-content">
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  Branding and Digital Marketing Services
                </h3>
                <p>
                  Our Branding and Digital Marketing services are designed to
                  propel your business forward in the online landscape. We
                  specialize in leveraging cutting-edge digital strategies to
                  boost your online presence, engage your target audience, and
                  drive conversions. We offer a wide range of digital marketing
                  solutions, including search engine optimization (SEO),
                  pay-per-click (PPC) advertising, social media marketing,
                  content marketing, and more. Our team of experts crafts
                  customized digital marketing campaigns tailored to your
                  specific business goals. Whether you're looking to increase
                  website traffic, improve search engine rankings, enhance your
                  brand's social media presence, or strengthen your overall
                  digital identity, our digital marketing services have you
                  covered. We stay updated with the latest industry trends and
                  tools to ensure your business stays ahead in the digital
                  arena.
                </p>
                <p>
                  Our approach is rooted in understanding your brand's essence
                  and your unique position in the market. We believe that
                  branding and digital marketing go hand in hand, and we're here
                  to help you not only attract audiences but also create a
                  lasting brand image. Our strategies are built on a foundation
                  of market research, goal setting, and comprehensive campaign
                  development.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-banner" style={{ marginTop: "80px" }}>
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:+212610475046">+212610475046</a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>01</span>Branding Strategy
                    </h4>
                    <p>
                      <b>Brand Identity:</b> We help you define a unique and
                      compelling brand identity that sets you apart from the
                      competition.
                      <br />
                      <b>Storytelling:</b> We craft engaging brand stories that
                      resonate with your target audience and create emotional
                      connections.
                      <br />
                      <b>Visual Branding:</b> Our experts design visually
                      appealing logos, graphics, and marketing materials that
                      reflect your brand's personality.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/blog/digital-marketing-plan.jpg"
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/blog/digital-marketing-excution.jpg"
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>02</span>Digital Marketing Execution
                    </h4>
                    <p>
                      <b>Content Creation:</b> We develop high-quality and
                      engaging content to drive traffic and capture audience
                      interest.
                      <br />
                      <b>SEO and PPC:</b> Our experts optimize your website for
                      search engines and manage pay-per-click advertising
                      campaigns for maximum visibility.
                      <br />
                      <b>Social Media Management:</b> We create and curate
                      content for your social media channels, engaging with your
                      audience and growing your brand presence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>03</span>Analysis and Optimization
                    </h4>
                    <p>
                      <b>Performance Tracking:</b> We monitor the performance of
                      your digital marketing campaigns, making real-time
                      adjustments for optimal results.
                      <br />
                      <b>Monthly Reports:</b> You receive detailed reports
                      outlining key performance metrics and insights, allowing
                      you to assess campaign success.
                      <br />
                      <b>Continuous Improvement:</b> Based on data-driven
                      insights, we continuously refine your digital marketing
                      strategy for maximum ROI.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/blog/digital-marketing-reporting.jpg"
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
