import React from "react";
import { Link } from "react-router-dom";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-8">
              <div className="service-details-content">
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  IT Consulting
                </h3>
                <p>
                  Our IT Consulting services are designed to help your business
                  navigate the complex world of technology. We offer strategic
                  guidance and expertise to optimize your IT infrastructure and
                  digital initiatives. Our team of experts works closely with
                  you to understand your unique business challenges and goals.
                </p>
                <p>
                  Our approach is centered on attentive listening to understand
                  your needs and concerns. We then follow a structured process
                  of research and preparation, which allows us to offer you the
                  best advice and strategies tailored to your project's success.
                  Our ultimate goal is to help you overcome challenges, make
                  informed decisions, and drive innovation in your IT
                  initiatives.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-banner" style={{ marginTop: "80px" }}>
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:+212610475046">+212610475046</a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>01</span>Listening to the Customer
                    </h4>
                    <p>
                      Our journey begins by putting you, the customer, at the
                      center of everything we do. We understand that your IT
                      project is not just a collection of technical
                      requirements; it's a strategic initiative that can shape
                      the future of your business. We listen attentively to your
                      needs, concerns, and aspirations, ensuring that your goals
                      become our goals.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/blog/consulting-listen.jpg"
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/blog/consulting-advice.jpg"
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>02</span>Research and Preparation
                    </h4>
                    <p>
                      Armed with a deep understanding of your project's unique
                      requirements, we delve into comprehensive research and
                      preparation. Our dedicated team conducts a meticulous
                      analysis, gathering valuable insights to lay the
                      groundwork for success. We consider technical intricacies,
                      financial implications, and operational nuances to provide
                      you with a holistic view of your project's landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>03</span>Providing the Best Advice
                    </h4>
                    <p>
                      Based on our research, we provide you with the best
                      advice, choices, and strategies tailored to your project's
                      success. We don't just aim to meet your expectations; we
                      strive to exceed them by offering solutions that align
                      with your business objectives and aspirations.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/blog/consulting-adv.jpg"
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
