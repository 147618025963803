import React from "react";
import { Avatar, List, Typography } from "antd";

const { Title } = Typography;

const SubscribersChart = ({ recentSubscribers }) => {
  return (
    <div id="chart">
      <div>
        <Title level={5}>Recent Subscribers</Title>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={recentSubscribers}
        renderItem={(subscriber) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${subscriber.id}`}
                />
              }
              title={
                <a href={`/admin/subscribers/${subscriber.id}`}>
                  {`${subscriber.type.toUpperCase()} - ${subscriber.email}`}
                </a>
              }
              description={`Subscribed on: ${new Date(
                subscriber.createdAt
              ).toLocaleDateString()}`}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default SubscribersChart;
