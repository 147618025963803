import Typography from "antd/es/typography/Typography";
import React from "react";
import Chart from "react-apexcharts";

const PieExpert = ({ expertiseDist }) => {
  const { Title } = Typography;

  const options = [
    { value: "webDevelopment", label: "Web Development" },
    { value: "mobileDevelopment", label: "Mobile Development" },
    { value: "digitalMarketing", label: "Branding & Digital Marketing" },
    { value: "uiUxDesign", label: "UI & UX Design" },
    { value: "itConsulting", label: "IT Consulting" },
  ];

  // Extract values and labels for the pie chart
  const chartSeries = Object.values(expertiseDist);
  const chartLabels = Object.keys(expertiseDist).map((key) => {
    const option = options.find((opt) => opt.value === key);
    return option ? option.label : key;
  });

  const digitalShiftsColors = [
    "#4FB590",
    "#67BFA6",
    "#7FCABD",
    "#80C0AC",
    "#77B6A3",
  ];

  const chartOptions = {
    series: chartSeries,
    chart: {
      width: "100%",
      type: "pie",
    },
    labels: chartLabels,
    theme: {
      monochrome: {
        enabled: false,
      },
    },
    colors: digitalShiftsColors,
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + "%"];
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Expertise Distribution</Title>
        </div>
      </div>
      <Chart options={chartOptions} series={chartOptions.series} type="pie" />
    </>
  );
};

export default PieExpert;
