import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React from "react";
import Sidebar from "../Sidebar";
import NewsletterSection from "./NewsletterSection";

const Newsletter = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={200}
        theme="dark"
        style={{
          backgroundColor: "#191a1c",
        }}
        className="sidebar-container"
      >
        <Sidebar selectedMenuItem={"Newsletter"} />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "16px", overflow: "auto" }}>
          <NewsletterSection />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Newsletter;
