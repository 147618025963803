import React, { useEffect, useState } from "react";
import { Button, message, Modal, Form, Input, Layout, Card } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { ContactService } from "../../../../services/services";
import "./ContactDetail.css";
import Sidebar from "../Sidebar";

const { TextArea } = Input;
const { Sider, Content } = Layout;
const { confirm } = Modal;

const ContactsDetail = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { contactId } = useParams();

  const [contact, setContact] = useState({});
  const [selectedMenuItem, setSelectedMenuItem] = useState("Contacts");

  const fetchContactDetails = async () => {
    try {
      const response = await ContactService.getContact(contactId);
      setContact(response.data);
      form.setFieldsValue(response.data);
    } catch (error) {
      console.error("Error fetching contact details: ", error);
    }
  };

  useEffect(() => {
    fetchContactDetails();
  }, []); // No dependencies, fetch only once when the component mounts

  const handleUpdate = async (values) => {
    try {
      await ContactService.updateContact(contactId, values);
      message.success("Contact updated successfully");
    } catch (error) {
      message.error("Error updating contact");
    }
  };

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this contact?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          await ContactService.deleteContact(contactId);
          history.push("/admin/contacts");
        } catch (error) {
          message.error("Error deleting contact");
        }
      },
    });
  };
  const transformDate = (timestamp) => {
    const date = new Date(timestamp);

    let MM = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    let DD = String(date.getDate()).padStart(2, "0");
    let YYYY = date.getFullYear();

    let HH = String(date.getHours()).padStart(2, "0");
    let MIMI = String(date.getMinutes()).padStart(2, "0");

    return `${MM}/${DD}/${YYYY} - ${HH}h${MIMI}`;
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={200}
        theme="dark"
        style={{
          backgroundColor: "#191a1c",
        }}
        className="sidebar-container"
      >
        <Sidebar
          selectedMenuItem={selectedMenuItem}
          onMenuItemClick={setSelectedMenuItem}
        />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "16px", overflow: "auto" }}>
          <Card
            className="contact-detail-card"
            title="Contact Details"
            bordered={false}
          >
            <Form
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              initialValues={contact}
              form={form}
              style={{ maxWidth: 600 }}
              onFinish={handleUpdate}
            >
              <Form.Item label="Name" name="name">
                <Input />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <Input />
              </Form.Item>
              <Form.Item label="phone" name="phone">
                <Input />
              </Form.Item>
              <Form.Item label="Subject" name="subject">
                <Input />
              </Form.Item>
              <Form.Item label="Message" name="message">
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item label="Comments" name="comments">
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item label="Received at">
                <Input disabled value={transformDate(contact.createdAt)} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                <div className="contact-actions">
                  <Button
                    type="primary"
                    style={{
                      margin: "12px 30px",
                      backgroundColor: "rgb(136 197 173)",
                    }}
                    htmlType="submit"
                  >
                    Update
                  </Button>
                  <Button
                    type="danger"
                    style={{ backgroundColor: "#e86262" }}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ContactsDetail;
