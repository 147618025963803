import React from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import lineChart from "./lineChart";

function InquiriesChart({ inquiriesTimeline }) {
  const { Title } = Typography;

  // Extract dates and counts from the inquiriesTimeline object
  const dates = Object.keys(inquiriesTimeline);
  const counts = Object.values(inquiriesTimeline);

  const updatedLineChartOptions = {
    ...lineChart.options,
    xaxis: {
      ...lineChart.options.xaxis,
      categories: dates,
    },
    colors: ["#5FCBA0"], // You can adjust the color as per your needs
  };

  const updatedLineChartSeries = [
    {
      name: "Number of Inquiries",
      data: counts,
    },
  ];

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Inquiries Over Time</Title>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={updatedLineChartOptions}
        series={updatedLineChartSeries}
        type="area"
        height={300}
        width={"100%"}
      />
    </>
  );
}

export default InquiriesChart;
