/* global cloudinary */
import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  message,
  Modal,
  Form,
  Input,
  InputNumber,
  Layout,
  Select,
  Card,
} from "antd";
import { useParams, useHistory } from "react-router-dom";
import Sidebar from "../Sidebar";
import { ExpertService } from "../../../../services/services";
import "./ExpertDetail.css";

const { TextArea } = Input;
const { Sider, Content } = Layout;
const { confirm } = Modal;

const ExpertDetail = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { expertId } = useParams();

  const [selectedMenuItem, setSelectedMenuItem] = useState("Experts");
  const [image, setUploadedImage] = useState(null);
  const [expert, setExpert] = useState({});
  const [techAndTools, setTechAndTools] = useState([]);

  const fetchExpertDetails = useCallback(async () => {
    try {
      const response = await ExpertService.getExpert(expertId);
      setExpert(response.data);
      form.setFieldsValue(response.data);
      setUploadedImage(response.data.image);
    } catch (error) {
      console.error("Error fetching expert details: ", error);
    }
  }, [expertId, form]);

  useEffect(() => {
    fetchExpertDetails();
  }, [fetchExpertDetails]); // Added expertId as a dependency for robustness

  const handleUpdate = async (values) => {
    try {
      values.image = image;
      await ExpertService.updateExpert(expertId, values);
      message.success("Expert updated successfully");
    } catch (error) {
      message.error("Error updating expert");
    }
  };
  const options = [
    { value: "webDevelopment", label: "Web Development" },
    { value: "mobileDevelopment", label: "Mobile Development" },
    { value: "digitalMarketing", label: "Branding & Digital Marketing" },
    { value: "uiUxDesign", label: "UI & UX Design" },
    { value: "itConsulting", label: "IT Consulting" },
  ];
  const handleDelete = () => {
    confirm({
      title: "Are you sure delete this expert?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          await ExpertService.deleteExpert(expertId);
        } catch (error) {
          message.error("Error deleting expert");
        }
      },
    });
    history.push("/admin/experts");
  };
  const transformDate = (timestamp) => {
    const date = new Date(timestamp);

    let MM = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    let DD = String(date.getDate()).padStart(2, "0");
    let YYYY = date.getFullYear();

    let HH = String(date.getHours()).padStart(2, "0");
    let MIMI = String(date.getMinutes()).padStart(2, "0");

    return `${MM}/${DD}/${YYYY} - ${HH}h${MIMI}`;
  };
  const openCloudinaryWidget = () => {
    cloudinary.openUploadWidget(
      {
        cloudName: "dbgva5f9l",
        uploadPreset: "ekpuybec",
        sources: ["local"],
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          setUploadedImage(result.info.secure_url);
        }
      }
    );
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme="dark"
        style={{
          backgroundColor: "#191a1c",
        }}
        className="sidebar-container"
      >
        <Sidebar
          selectedMenuItem={selectedMenuItem}
          onMenuItemClick={setSelectedMenuItem}
        />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "16px", overflow: "auto" }}>
          <Card
            className="expert-detail-card"
            title="Expert Details"
            bordered={false}
          >
            <Form
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              initialValues={expert}
              form={form}
              style={{ maxWidth: 600 }}
              onFinish={handleUpdate}
            >
              <Form.Item label="Profile Image">
                <div>
                  {image && (
                    <img
                      src={image}
                      alt="Uploaded Preview"
                      style={{ width: "100px", marginTop: "10px" }}
                    />
                  )}
                </div>{" "}
                <Button type="button" onClick={openCloudinaryWidget}>
                  Upload Image
                </Button>
              </Form.Item>
              <Form.Item label="Name" name="name">
                <Input />
              </Form.Item>
              <Form.Item label="Title" name="title">
                <Input />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <Input />
              </Form.Item>
              <Form.Item label="Contact Number" name="contactNumber">
                <Input />
              </Form.Item>
              <Form.Item label="LinkedIn" name="linkedin">
                <Input />
              </Form.Item>
              <Form.Item label="Years of Experience" name="yearsOfExperience">
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item label="Portfolio URL" name="portfolioUrl">
                <Input />
              </Form.Item>
              <Form.Item label="Brief Statement" name="briefStatement">
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item label="Tech & Tools" name="techAndTools">
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Tech and Tools that You Master (e.g., Reactjs, Adobe Photoshop, Facebook Ads, Flutter, Figma, etc.)"
                  value={techAndTools} // Bind the current tags from the state
                  onChange={(newTags) => setTechAndTools(newTags)} // Update the state when new tags are added or removed
                  tokenSeparators={[","]} // Allow tags to be created when comma is pressed
                >
                  {techAndTools.map(
                    (
                      tech // Render the current tags as options
                    ) => (
                      <Select.Option key={tech} value={tech}>
                        {tech}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item label="Area of Expertise" name="areaOfExpertise">
                <Select
                  mode="multiple"
                  placeholder="Select areas of expertise"
                  options={options.map((opt) => ({
                    value: opt.value,
                    label: opt.label,
                  }))}
                />
              </Form.Item>
              <Form.Item label="Comments" name="comments">
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item label="Score" name="score">
                <Input />
              </Form.Item>
              <Form.Item label="Join Digital Shifts Network On">
                <Input disabled value={transformDate(expert.createdAt)} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                <div className="expert-actions">
                  <Button
                    type="primary"
                    style={{
                      margin: "12px 30px",
                      backgroundColor: "rgb(136 197 173)",
                    }}
                    htmlType="submit"
                  >
                    Update
                  </Button>
                  <Button
                    type="danger"
                    style={{ backgroundColor: "#e86262" }}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ExpertDetail;
