import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ContactWrapper from "./ContactWrapper";
import AnimateLine from "../../common/AnimateLine";
import { useLocation } from "react-router-dom";
import siteMetadata from "../../../utiles/seo/siteMetadata";
import SEO from "../../../utiles/seo/SEO";

function Contact() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { title, description, keywords } =
    siteMetadata.paths[currentPath] || siteMetadata.common;

  return (
    <>
      <SEO
        path={currentPath}
        title={title}
        description={description}
        keywords={keywords}
      />
      <div className="digital-shifts-wrap">
        <AnimateLine />
        <Breadcrumb pageTitle="Contact us" pageName="Contact us" />
        <ContactWrapper />
        <SubscribArea />
      </div>
    </>
  );
}

export default Contact;
