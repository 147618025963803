import React from "react";
import { Link } from "react-router-dom";

function ServiceDetailsWrap() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="service-details sec-mar-top">
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-lg-8">
              <div className="service-details-content">
                <h3>
                  <i>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/img/icons/service-details-icon.png"
                      }
                      alt="service icon images"
                    />
                  </i>
                  Website Development Services
                </h3>
                <p>
                  In our Software Development services, we embark on a
                  comprehensive journey through the software development life
                  cycle. From ideation to deployment, we harness cutting-edge
                  technologies and methodologies to craft tailored software
                  solutions that align with your business objectives. Leveraging
                  languages like Python, Java, and JavaScript, along with modern
                  frameworks and libraries, we create robust and scalable
                  applications. Our adept team of developers ensures seamless
                  integration of databases, APIs, and user interfaces,
                  guaranteeing an optimized user experience. We are committed to
                  delivering software that's not only functional but also aligns
                  with industry best practices, ensuring your business stays
                  ahead in the digital landscape.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-banner" style={{ marginTop: "80px" }}>
                <img
                  src={process.env.PUBLIC_URL + "/img/widget-banner-bg.jpg"}
                  alt="Banner images"
                />
                <div className="banner-inner">
                  <h3>
                    Any Project <span>Call Now.</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:+212610475046">+212610475046</a>
                </div>
              </div>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>01</span>Planning and Analysis
                    </h4>
                    <p>
                      <b>Requirements Gathering:</b> We work closely with you to
                      understand your specific needs, objectives, and
                      constraints. This phase involves extensive discussions and
                      documentation of project requirements.
                      <br />
                      <b>Feasibility Study:</b> We conduct a feasibility study
                      to assess the practicality of the project, considering
                      technical, financial, and operational aspects. <br />
                      <b>Project Planning:</b> Our team creates a detailed
                      project plan outlining timelines, resource allocation, and
                      deliverables. We define the project scope and identify
                      potential risks.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/img/blog/thumb-1.jpg"}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/img/blog/thumb-2.jpg"}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>02</span>Development and Implementation
                    </h4>
                    <p>
                      <b>Design:</b> Our experienced designers create a
                      blueprint of the software's user interface (UI) and
                      architecture. We focus on usability, scalability, and
                      performance. <br />
                      <b>Coding:</b> Our developers bring the design to life by
                      writing clean, efficient, and maintainable code. We follow
                      industry best practices and coding standards. <br />
                      <b>Testing:</b> We perform rigorous testing, including
                      unit testing, integration testing, and user acceptance
                      testing, to ensure the software functions flawlessly.{" "}
                      <br />
                      <b>Deployment:</b> Once the software passes all tests, we
                      deploy it to your infrastructure or cloud environment. We
                      provide support during the deployment phase to ensure a
                      smooth transition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item" style={{ height: "450px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>03</span>Maintenance and Support
                    </h4>
                    <p>
                      <b>Post-Deployment Support:</b> Our commitment doesn't end
                      with deployment. We offer ongoing maintenance and support
                      services to address any issues, release updates, and
                      enhance the software's functionality.
                      <br />
                      <b>Optimization:</b> We continuously monitor the
                      software's performance and make optimizations to improve
                      efficiency and user experience. <br />
                      <b>Scalability:</b> As your business grows, we help you
                      scale the software to accommodate increased demands,
                      ensuring it remains a valuable asset.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img
                      src={process.env.PUBLIC_URL + "/img/blog/thumb-3.jpg"}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default ServiceDetailsWrap;
