import React from "react";
import CountUp from "react-countup";
function FeaturesArea() {
  return (
    <>
      <section className="features-area">
        <div className="container">
          <div
            className="title-wrap  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title white">
              <span>Key Numbers</span>
              <h2 style={{ color: "#E4E4E4" }}>Highlights</h2>
              <p style={{ color: "#E4E4E4" }}>
                Proudly showcasing a remarkable track record, Digital Shifts
                exemplifies excellence, innovation, and key milestones in
                unlocking digital potential.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-4.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={3} delay={3} duration={2} /> <sup>+</sup>
                  <h4>Years of Experience</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-1.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={10} delay={3} duration={2} /> <sup>+</sup>
                  <h4>Project Completed</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-2.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={7} delay={3} duration={2} /> <sup>+</sup>
                  <h4>Satisfied Clients</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-3.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={8} delay={3} duration={2} /> <sup>+</sup>
                  <h4>Expert Teams</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeaturesArea;
