import { useEffect, useState } from "react";
import { Table, Spin, Tag, Input, Button, Space, Image } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ExpertService } from "../../../../services/services";

const ExpertsSection = () => {
  const [expandedRowKey, setExpandedRowKey] = useState("");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const response = await ExpertService.getAllExperts({
          page: currentPage,
          limit: 10000, // assuming 6 is your desired page size
        });

        if (isMounted) {
          setData(response.data.results);
          setTotalData(response.data.totalResults); // assuming the total count is in 'total' property of your response
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    fetchData();
    return () => {
      isMounted = false; // on cleanup, set the flag to false
    };
  }, []); // Add 'currentPage' as a dependency to re-fetch data when the page number changes

  const handleExpand = (record) => {
    if (expandedRowKey === record.key) {
      // Collapse the row if it's already expanded
      setExpandedRowKey("");
    } else {
      // Expand the clicked row
      setExpandedRowKey(record.key);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <a href={`/admin/experts/${record.id}`}>{text}</a>
      ),
      ...getColumnSearchProps("name"), // Apply the custom column search
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      ...getColumnSearchProps("title"), // Apply the custom column search
    },
    {
      title: "Area of Expertise",
      dataIndex: "areaOfExpertise",
      key: "areaOfExpertise",
      filters: [
        { text: "Web Development", value: "webDevelopment" },
        { text: "Mobile Development", value: "mobileDevelopment" },
        { text: "Branding & Digital Marketing", value: "digitalMarketing" },
        { text: "UI & UX Design", value: "uiUxDesign" },
        { text: "IT Consulting", value: "itConsulting" },
      ],
      onFilter: (value, record) => record.areaOfExpertise.includes(value),
      render: (_, { areaOfExpertise }) => (
        <>
          {areaOfExpertise.map((tag) => {
            const color = generateColor(tag.length);
            return (
              <Tag color={color} key={tag}>
                <span style={{ color: "black" }}>{tag.toUpperCase()}</span>
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Tech and Tools",
      dataIndex: "techAndTools",
      ...getColumnSearchProps("techAndTools", true),
      key: "techAndTools",
      render: (_, { techAndTools }) => (
        <div className="cell-content">
          {techAndTools.map((tag) => {
            const color = generateColor(tag.length);
            return (
              <Tag color={color} key={tag}>
                <span style={{ color: "black" }}>{tag.toUpperCase()}</span>
              </Tag>
            );
          })}
        </div>
      ),
    },
    {
      title: "Portfolio",
      dataIndex: "portfolioUrl",
      key: "portfolioUrl",
      render: (portfolioUrl) => (
        <a href={portfolioUrl} target="_blank" rel="noopener noreferrer">
          {portfolioUrl}
        </a>
      ),
    },
    {
      title: "Linkedin",
      dataIndex: "linkedin",
      key: "linkedin",
      render: (linkedin) => (
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          {linkedin}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"), // Apply the custom column search
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
      ...getColumnSearchProps("contactNumber"), // Apply the custom column search
    },
    {
      title: "Years of Exp",
      dataIndex: "yearsOfExperience",
      key: "yearsOfExperience",
      width: 80,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },

    {
      title: "Score",
      dataIndex: "score",
      fixed: "right",
      key: "score",
      width: 80,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ];
  const isLoading = !data.length;
  return (
    <Spin spinning={isLoading} size="large">
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: expandedRowRender,
          expandedRowKeys: [expandedRowKey],
          onExpand: (expanded, record) => handleExpand(record),
        }}
        dataSource={data.map((record) => ({
          ...record,
          key: record.id,
        }))}
        scroll={{ x: 1500, y: 300 }}
        pagination={{
          current: currentPage,
          pageSize: 6,
          total: totalData,
          position: ["bottomCenter"],
          onChange: (page) => setCurrentPage(page), // Update the current page when table pagination changes
        }}
      />
    </Spin>
  );
};

const expandedRowRender = (record) => (
  <div>
    {record.image && <Image width={200} src={record.image} />}
    {record.briefStatement && (
      <p>
        <strong>Brief Statement:</strong> {record.briefStatement}
      </p>
    )}
    {record.comments && (
      <p>
        <strong>Comments:</strong> {record.comments}
      </p>
    )}
  </div>
);

const generateColor = (length) => {
  const hue = (300 * length) / 20; // Vary hue based on the length (adjust 20 as needed)
  return `hsl(${hue}, 40%, 70%)`; // Generate an HSL color
};
// Function to add custom search functionality for each column
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() =>
        document.querySelector(`[placeholder*="${dataIndex}"]`).select()
      );
    }
  },
});

export default ExpertsSection;
