import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './components/App';
import MainLayout from './components/layout/MainLayout';
import About from './components/page/about/About';
import { Analytics } from '@vercel/analytics/react';

// import Faq from "./components/page/faq/Faq";
// import HomePageTwo from "./components/page/homepage2/HomePageTwo";
// import Pricing from "./components/page/pricing/Pricing";
// import Team from "./components/page/team/Team";
// import queryString from "query-string";
// import ProjectDetails from "./components/page/ProjectDetails/ProjectDetails";

import Contact from './components/page/contact/Contact';
import BeOurExpert from './components/page/beOurExpert/BeOurExpert';
import ErrorPage from './components/page/error/ErrorPage';
import Login from './components/page/admin/Login';
import ExpertDetail from './components/page/admin/experts/ExpertDetail';
import Projects from './components/page/project/Projects';
import Service from './components/page/service/Service';
import ServiceDetails from './components/page/ServiceDetails/ServiceDetails';
import './index.css';
import PrivateRoute from './utiles/PrivateRoute';
import Dashboard from './components/page/admin/dashboard/Dashboard';
import Experts from './components/page/admin/experts/Experts';
import Contacts from './components/page/admin/contacts/Contacts';
import Newsletter from './components/page/admin/newsletter/Newsletter';
import ContactsDetail from './components/page/admin/contacts/ContactsDetail';
import Logout from './components/page/admin/Logout';
import Meeting from './components/page/meetings/Meeting';
import Terms from './components/page/terms/Terms';

//Default Warniing Error Hide
//console.log = console.warn = console.error = () => {};

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

function Root() {
  return (
    <>
      <Analytics />
      <BrowserRouter basename='/'>
        <Switch>
          {/*main layout*/}
          <Route exact path='/' component={MainLayout} />
          <Route exact path='/admin/login' component={Login} />
          <Route exact path='/admin/logout' component={Logout} />
          <PrivateRoute
            exact
            path='/admin/experts/:expertId'
            component={ExpertDetail}
          />
          <PrivateRoute
            exact
            path='/admin/contacts/:contactId'
            component={ContactsDetail}
          />
          <PrivateRoute path='/admin/dashboard' component={Dashboard} />
          <PrivateRoute path='/admin/experts' component={Experts} />
          <PrivateRoute path='/admin/contacts' component={Contacts} />
          <PrivateRoute path='/admin/newsletter' component={Newsletter} />

          {/* secound layout */}
          {/* <Route exact path="/index2" component={HomePageTwo} /> */}
          {/* all inner page load layout component */}
          <Layout>
            <Switch>
              {/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/about-us`}
          component={AboutPage}
        /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/about`}
                component={About}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/contact`}
                component={Contact}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/schedule-meeting`}
                component={Meeting}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/terms`}
                component={Terms}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/beOurExpert`}
                component={BeOurExpert}
              />
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/error`}
              component={ErrorPage}
            /> */}

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/services`}
                component={Service}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/services/website-development`}
                render={() => <ServiceDetails service='Website Development' />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/services/mobile-app-development`}
                render={() => (
                  <ServiceDetails service='Mobile App Development' />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/services/branding-digital-marketing`}
                render={() => (
                  <ServiceDetails service='Branding and Digital Marketing' />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/services/ui-ux-design`}
                render={() => <ServiceDetails service='UI/UX Design' />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/services/it-consulting`}
                render={() => <ServiceDetails service='IT Consulting' />}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/projects`}
                component={Projects}
              />
              <Route component={ErrorPage} />

              {/* <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-details`}
          component={ProjectDetails}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project`}
          component={Project}
        /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/team`}
              component={Team}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/pricing`}
              component={Pricing}
            /> */}
              {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={Faq}
            /> */}
              {/* 
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/team`}
          component={TeamPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/feedback`}
          component={FeedbackPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/faq`}
          component={FaqPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/error`}
          component={ErrorPage}
        />
        
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project`}
          component={ProjectPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-details`}
          component={ProjectDetailsPage}
        />
      
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/service-details`}
          component={ServiceDetailsPage}
        /> */}
            </Switch>
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('root'));
