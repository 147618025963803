import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AnimateLine from "../../common/AnimateLine";

function Terms() {
  return (
    <>
      <div className="digital-shifts-wrap">
        <AnimateLine />
        <Breadcrumb
          pageTitle="Terms & Conditions"
          pageName="Terms & Conditions"
        />
        <div
          className="container terms"
          style={{ minWidth: "320px", paddingTop: "90px" }}
        >
          <h2>Terms and Conditions</h2>
          <p>
            Welcome to DigitalShifts.net. By using this website, you agree to
            comply with and be bound by the following terms and conditions of
            use. If you do not agree to these terms and conditions, you should
            not use this website.
          </p>
          <section>
            <h3>1. Introduction</h3>
            <p>
              Welcome to Digital Shifts. By accessing our website
              (https://www.digitalshifts.net) or using our services, you agree
              to be bound by the following terms and conditions.
            </p>
          </section>
          <section>
            <h3>2. Definitions</h3>
            <p>
              <strong>"We", "Us", "Our"</strong>: Refers to Digital Shifts.
            </p>
            <p>
              <strong>"Services"</strong>: Encompasses all services offered by
              Digital Shifts, including web development, digital marketing,
              UI/UX design, and IT consulting.
            </p>
            <p>
              <strong>"User", "You", "Your"</strong>: Any individual or entity
              using our website or services.
            </p>
          </section>
          <section>
            <h3>3. User Obligations</h3>
            <p>
              You agree to use our website and services for lawful purposes and
              in a way that does not infringe upon the rights of or restrict
              other users.
            </p>
            <p>
              You are responsible for maintaining the confidentiality of your
              account information and for all activities under your account.
            </p>
          </section>
          <section>
            <h3>4. Intellectual Property</h3>
            <p>
              All content on this website, including text, graphics, logos, and
              software, is the property of Digital Shifts and is protected under
              intellectual property laws.
            </p>
            <p>
              You may not reproduce, redistribute, or exploit our content
              without explicit written consent from us.
            </p>
          </section>
          <section>
            <h3>5. Service Provision</h3>
            <p>
              We reserve the right to modify, suspend, or discontinue any aspect
              of our services at any time.
            </p>
            <p>
              We are not liable for any modifications, suspensions, or
              discontinuance of any of our services.
            </p>
          </section>
          <section>
            <h3>6. Limitation of Liability</h3>
            <p>
              Digital Shifts shall not be liable for any indirect, incidental,
              special, consequential, or punitive damages resulting from the use
              of our services.{" "}
            </p>
          </section>
          <section>
            <h3>7. Indemnification</h3>
            <p>
              You agree to indemnify and hold harmless Digital Shifts and its
              employees, agents, and affiliates from any claims, damages, or
              expenses arising from your use of the website or services.
            </p>
          </section>
          <section>
            <h3>8. Termination</h3>
            <p>
              We reserve the right to terminate or suspend access to our
              services immediately, without prior notice or liability, for any
              reason.
            </p>
          </section>
          <section>
            <h3>9. Changes to Terms</h3>
            <p>
              We reserve the right to modify these terms at any time. Your
              continued use of the website and services following any changes
              indicates your acceptance of the new terms.
            </p>
          </section>
          <section>
            <h3>10. Governing Law</h3>
            <p>
              These terms shall be governed by the laws of the jurisdiction in
              which Digital Shifts is registered. Any disputes will be resolved
              in the local courts of this jurisdiction.
            </p>
          </section>
          <section>
            <h3>11. Contact Us</h3>
            <p>
              For any questions about these terms, please contact us at (contact
              information).
            </p>
          </section>
          <br />
          <i>Last Updated: 20-11-2023</i>
          <SubscribArea />
        </div>
      </div>
    </>
  );
}

export default Terms;
